import { TextSection } from "components/TextSection";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { ReactComponent as BodySVG } from "assets/content/results/body.svg";
import { ReactComponent as EighteenSVG } from "assets/content/results/18.svg";
import { ReactComponent as ImmuneSystemSVG } from "assets/content/results/immune.svg";
import titleImg from "assets/content/pro-hero-subscribe.png";
import one from "../Home/assets/img/1.png";
import two from "../Home/assets/img/2.png";
import three from "../Home/assets/img/3.png";
import four from "../Home/assets/img/4.png";
import five from "../Home/assets/img/5.png";
import six from "../Home/assets/img/6.png";
import seven from "../Home/assets/img/7.png";
import grazia from "../Home/assets/img/grazia.png";
import elle from "../Home/assets/img/elle.png";
import peopletalk from "../Home/assets/img/peopletalk.png";
import cosmo from "../Home/assets/img/cosmo.png";
import instyle from "../Home/assets/img/instyle.png";
import bazaar from "../Home/assets/img/bazaar.png";

import { ReactComponent as ForkAndSVG } from "assets/content/results/fork_spoon.svg";
import { ReactComponent as FlashSVG } from "assets/content/results/flash.svg";
import { ReactComponent as BackSideSVG } from "assets/content/results/back_side.svg";
import { ReactComponent as StandSVG } from "assets/content/results/stand.svg";
import { ReactComponent as StomachSVG } from "assets/content/results/stomach.svg";
import { ReactComponent as InfographySVG } from "assets/content/pro/infography.svg";
import { ReactComponent as InfographyMobileSVG } from "assets/content/pro/infography-mobile.svg";

import { useRef } from "react";
import { Link } from "react-router-dom";
import { Footer } from "components/Footer";

const intro = (
  <>
    <div className="prose mx-auto hidden sm:block md:block lg:block">
      <div className="flex gap-5 justify-center flex-wrap mb-5">
        <Link className="w-44 h-44 m-0 shrink-0" to="/bodyflex">
          <img className="w-44 h-44 m-0" src={one} alt="title" />
        </Link>
        <Link className="w-44 h-44 m-0 shrink-0" to="/oxycise">
          <img className="w-44 h-44 m-0" src={two} alt="title" />
        </Link>
        <Link className="w-44 h-44 m-0 shrink-0" to="/charger">
          <img className="w-44 h-44 m-0" src={three} alt="title" />
        </Link>
      </div>
      <div className="flex gap-5 justify-center flex-wrap md:flex-nowrap lg:flex-nowrap">
        <Link className="w-44 h-44 m-0 shrink-0" to="/pro">
          <img className="w-44 h-44 m-0" src={four} alt="title" />
        </Link>
        <Link className="w-44 h-44 m-0 shrink-0" to="/vip">
          <img className="w-44 h-44 m-0" src={five} alt="title" />
        </Link>

        <img className="w-44 h-44 m-0 opacity-20" src={six} alt="title" />
        <img className="w-44 h-44 m-0 opacity-20" src={seven} alt="title" />
      </div>
    </div>

    <div className="prose flex flex-col mx-auto sm:hidden md:hidden lg:hidden gap-10">
      <Link className="w-full m-0 shrink-0" to="/bodyflex">
        <img className="w-full h-full m-0" src={one} alt="title" />
      </Link>

      <Link className="w-full m-0 shrink-0" to="/oxycise">
        <img className="w-full h-full m-0" src={two} alt="title" />
      </Link>

      <Link className="w-full m-0 shrink-0" to="/charger">
        <img className="w-full h-full m-0" src={three} alt="title" />
      </Link>

      <Link className="w-full m-0 shrink-0" to="/pro">
        <img className="w-full h-full m-0" src={four} alt="title" />
      </Link>

      <Link className="w-full m-0 shrink-0" to="/vip">
        <img className="w-full h-full m-0" src={five} alt="VIP" />
      </Link>
      <img className="w-full h-full m-0  opacity-20" src={six} alt="title" />
      <img className="w-full h-full m-0  opacity-20" src={seven} alt="title" />
    </div>

    <p>
      Шпакметод – уникальный авторский метод дыхательных тренировок, основанный
      на использовании техник диафрагмального дыхания, современных упражнений и
      энергетических практик. Шпакметод направлен на быстрое жиросжигание,
      укрепление иммунитета БЕЗ ДИЕТ И ОГРАНИЧЕНИЙ за 10-20 минут в день, НЕ
      ВЫХОДЯ ИЗ ДОМА.
    </p>

    <p>
      Шпакметод – это МЕНТАЛЬНЫЙ ФИТНЕС, который поможет повысить уровень
      энергии, развить осознанность и улучшить качество жизни и тела.
    </p>

    <p>Как начать заниматься #шпакметод?</p>

    <p>
      Новеньким нужно начать с освоения тренировки #шпакметод_зарядка, который сделан
      по мотивам книги Алины Шпак «Ешь, Пей, Дыши, Худей», а далее перейти к
      другим тренировкам Алины с более агрессивными техниками дыхания.
    </p>

    <p>
      Конечная цель #шпакметод может быть разной: от похудения до достижения
      внутреннего баланса
    </p>
    <h1 className="text-rose-500">Как это работает?</h1>
    <p>
      <strong>Жир, или липиды</strong> -это своеобразное топливо в теле
      человека, когда мы определенным образом дышим-мы окисляем это топливо.
      Липиды расщепляются, и мы избавляемся от жировых отложений в тех местах,
      где их больше всего. Только в аэробных условиях можно добиться окисления
      жирных кислот, превращение жира в энергию происходит именно благодаря
      дыханию.
    </p>
    <p className="text-rose-500">
      В методе Алины Шпак мы применяем различные техники дыхания, которые
      помогают насытить тела добавочным кислородом
    </p>
  </>
);

const resultIconStyle =
  "text-white text-sm flex flex-col items-center gap-4 w-52 mb-10";
const textSpanStyle = "flex justify-center text-center items-center text-sm";

const resultsContent = (
  <div className="bg-rose-500 prose min-w-full p-4 gap-x-8 text-center">
    <h1 className="text-white font-serif mt-20">Результаты:</h1>
    <div className="flex flex-wrap justify-around items-center mt-10">
      <div className={resultIconStyle}>
        <BodySVG />
        <div>
          <span className={textSpanStyle}>
            Сжигание жира, уменьшение висцирального жира
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <BackSideSVG />
        <div>
          <span className={textSpanStyle}>
            Избавление от отечности и целлюлита
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <ImmuneSystemSVG />
        <div>
          <span className={textSpanStyle}>
            Улучшение работы имунной системы
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <StomachSVG />
        <div>
          <span className={textSpanStyle}>
            Улучшение работы кишечника. сокращение размеров желудка
          </span>
        </div>
      </div>
    </div>
    <div className="flex flex-wrap justify-around items-center">
      <div className={resultIconStyle}>
        <StandSVG />
        <div>
          <span className={textSpanStyle}>
            Стимулирование лактации и активация работы яичников
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <ForkAndSVG />
        <div>
          <span className={textSpanStyle}>
            Укращение метаболизма и снижение аппетита
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <FlashSVG />
        <div>
          <span className={textSpanStyle}>
            Устранение синдрома хронической усталости
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <EighteenSVG />
        <div>
          <span className={textSpanStyle}>
            Повышение сексуального желания и половой активности
          </span>
        </div>
      </div>
    </div>
  </div>
);

const aboutMe = (
  <div className="">
    <div>
      <p>15 лет назад я была в активном поиске…</p>
      <p>Нет, не мужчины, а способа сжечь жир и иметь стройное тело ВСЕГДА.</p>
      <p>
        <strong>Что же я сделала?</strong>
      </p>
      <p>
        Я стала исследовать традиционные и нетрадиционные системы тренировок и
        обнаружила как с кайфом заниматься своим телом и душой при этом НЕ
        ОТКАЗЫВАЯ себе в еде и в алкоголе,{" "}
        <strong>БЕЗ ДИЕТ, ЖЕСТКИХ ТРЕНИРОВОК</strong> и вообще
        <strong> НЕ ВЫХОДЯ ИЗ ДОМА</strong>.
      </p>
      <p>
        <strong>
          Меня зовут <span className="text-rose-500">Алина Шпак</span> и я
          тренер-преподаватель по дыхательным гимнастикам и энергетическим
          практикам
        </strong>
        , автор бестселлера ЕШЬ, ПЕЙ, ДЫШИ, ХУДЕЙ, тренер года по версии
        Соsmopolitan. Больше 10 лет я сдуваю ваши жирные попки, учу дышать и
        худеть. Я тренирую женщин, мужчин, звезд, олимпийских чемпионов, людей с
        ограниченными возможностями и т.д.
      </p>
      <p>
        В основе моего подхода лежат научные знания и практический опыт! За
        плечами З образования, Гарвард, Йельский университет, Корейский ведущий
        научно-технический университет и заканчивать этот список я не планирую!
        Я расскажу тебе как с кайфом заниматься своим телом и душой БЕЗ ЦЕНЗУРЫ,
        без воды, мучений, все будет четко по факту! Помимо этого я владею
        сотнями энергетических практик - на деньги, любовь, через
        которыепройдешь со мной и ты! Узнаешь правила вселенной и начнешь их
        применять. Все просто.
        <p>
          <span className="text-rose-500">ШпакМетод</span> - авторский метод
          быстрых, жиросжигающих тренировок на основе дыхательных гимнастик,
          который не требует диет и ограничений даже в алкоголе, укрепляющий
          иммунитет и повышающий уровень энергии.
        </p>
        <p>
          <span className="text-rose-500">ШпакМетод</span> - это ментальный
          фитнес с «жиросжигающим эффектом».
        </p>
      </p>
    </div>
  </div>
);

const articles = (
  <>
    <h1 className="text-center">Статьи</h1>
    <div className="flex flex-col md:flex-row justify-between content-center items-center">
      <a href="https://www.thesymbol.ru/beauty/health/kak-pohudet-bez-trenirovok-c-pomoshchyu-dyhatelnyh-praktik/">
        <img className="w-44 h-full" src={bazaar} alt="BAZAAR" />
      </a>
      <a href="https://graziamagazine.ru/lifestyle/dyshite-ne-dyshite-6-mifov-o-bodiflekse/amp/">
        <img className="w-40 h-full" src={grazia} alt="GRAZIA" />
      </a>
      <a
        href="https://www.elle.ru/krasota/esh-pei-dyshi-khudei-kak-s-pomoshyu-dykhatelnoi-praktiki-mozhno-sbrosit-ves/

"
      >
        <img className="w-32 h-full" src={elle} alt="Elle" />
      </a>
    </div>
    <div className="flex flex-col md:flex-row justify-between content-center items-center">
      <a href="https://peopletalk.ru/article/chto-delat-esli-vse-ploho-eksklyuzivnye-sovety-ot-korolevy-bodifleksa-i-blogera-aliny-shpak/">
        <img className="w-52 h-full" src={peopletalk} alt="PEOPLETALK" />
      </a>
      <a
        href="https://www.thevoicemag.ru/health/sport/dyshu-i-hudeyu-3-tehniki-bodifleksa-chtoby-szhigat-zhir-bystree/
"
      >
        <img className="w-40 h-full" src={cosmo} alt="COSMO.RU" />
      </a>
      <img className="w-40 h-full" src={instyle} alt="InStyle" />
    </div>
  </>
);

const Header = () => {
  return (
    <div className="absolute w-full">
      <nav className="bg-header flex pl-9 w-full items-center justify-center top-0 z-10">
        <div className="h-16 flex justify-center items-center flex-none">
          <Logo className="cursor-pointer" />
        </div>
      </nav>
    </div>
  );
};
const Insertion = ({ introRef }: any) => {
  return (
    <>
      <div className="bg-red-100 relative lg:hidden"></div>
      <div className="flex mx-auto bg-proHeroBg bg-no-repeat bg-center bg-cover w-auto h-screen bg-rose-200 lg:visible content-center lg:justify-start items-center">
        <div className="flex lg:content-center items-center sm:justify-end md:justify-center lg:justify-center sm:content-end h-full p-8">
          <a href="https://t.me/alishka_shpak">
            <img
              className="lg:ml-6 shrink-0 lg:max-w-xl lg:self-center mt-32 lg:mt-0 md:mb-2 md:self-end self-center md:w-3/4"
              src={titleImg}
              alt=""
            />
          </a>
        </div>
      </div>
      <div ref={introRef} />
    </>
  );
};

const About = () => {
  return (
    <div className="flex md:flex-row flex-col">
      <div className="md:w-1/2 bg-proAboutMeBg bg-no-repeat bg-top bg-cover">
        {" "}
        <a href="https://t.me/alishka_shpak">
          <img
            className="md:hidden"
            src={require("assets/content/about-me.jpg")}
            alt="Обо мне"
          />
        </a>
      </div>
      <div className="md:w-1/2">
        <article className="prose mx-auto text-base font-serif container p-4">
          {aboutMe}
        </article>
      </div>
    </div>
  );
};

export const Home = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const introRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={topRef}>
      <Header />
      <Insertion introRef={introRef} />
      <TextSection content={intro} />
      {resultsContent}
      <About />
      <InfographySVG className="hidden md:block" width="100%" height="100%" />
      <InfographyMobileSVG className="md:hidden" width="100%" height="100%" />
      <TextSection content={articles} />
      <div className="bg-separator h-16 bg-repeat-x" />
      <Footer />
    </div>
  );
};
