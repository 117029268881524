import { ChatMessages } from "components/ChatMessages";
import { ChatMessageType } from "components/ChatMessages/ChatMessages";
import { Footer } from "components/Footer";
import { HeroImageContainer } from "components/HeroImageContainer";
import { Products } from "components/Products";
import { TextSection } from "components/TextSection";
import { ReactComponent as ConsultationSVG } from "assets/svg/consultation.svg";
import alinaOnSofa from "./img/alina-on-sofa.png";
import { ReactComponent as HeroBox } from "./img/hero-back.svg";
import { Header } from "components/Header";
import { useRef } from "react";

const HeroVip = () => {
  return (
    <>
      <div className="relative hidden sm:hidden md:block">
        <div>
          <div className="absolute w-full">
            <HeroBox className="z0 w-full" />
          </div>
          <div className="relative bg-[#F4F2F3]">
            <div className="clip-hero-bg-vip absolute z-0 h-full w-full bg-rose-500"></div>
            <div className="prose z-50 mx-auto">
              <div className="z-10 flex flex-col">
                <p className="z-10 mb-0 font-serif text-8xl font-extrabold leading-none text-white">
                  VIP тренировка
                </p>

                <h2 className="z-50 mt-8 font-serif text-6xl font-semibold leading-none	text-white">
                  с Алиной Шпак
                </h2>
              </div>
              <div className="z-100 relative flex flex-col">
                <h1 className="prose z-50 mb-0 font-serif text-6xl font-semibold">
                  «Дыши и худей»
                </h1>
                <h2 className="prose z-50 mt-0 font-serif text-4xl">
                  8 минут в день для идеального тела
                </h2>
              </div>
            </div>
            <img
              src={alinaOnSofa}
              className="absolute right-20 bottom-0 z-40 w-96"
              alt="Алина лежит в кресле"
            />
          </div>
        </div>
      </div>
      <div className="h-screen relative bg-[#F4F2F3] pt-2 sm:block md:hidden">
        <div className="clip-hero-bg-vip-mobile mt-14 h-44 bg-red-500 p-4">
          <h2 className="mx-auto mb-2 mt-6 px-4 font-serif text-5xl font-extrabold leading-none text-white">
            VIP тренировка
          </h2>
          <h2 className="px-4 font-serif text-4xl font-extrabold leading-none text-white">
            с Алиной Шпак
          </h2>
        </div>
        <div className="prose mt-4 px-4">
          <h2 className="mb-0 whitespace-nowrap py-6 mt-0 font-serif text-5xl font-extrabold leading-none">
            «Дыши и худей»
          </h2>
          <h2 className="text-4xl py-2 font-serif font-extrabold leading-none">
            8 минут в день для идеального тела
          </h2>
        </div>
        <img
          src={alinaOnSofa}
          className="absolute left-0 bottom-0 z-40"
          alt="Алина лежит в кресле"
        />
      </div>
    </>
  );
};

const AboutSection = () => {
  const content = (
    <div>
      <h1><span className="text-rose-500">Ешь, пей, дыши, худей ™</span></h1>
      {/* <h1>
        Одна цель — сделать из вас{" "}
        <span className="text-rose-500">@хуенных королев</span>
      </h1> */}
      <p>
        Каждый тренировка с Алиной Шпак уникален, каждый раз - это разные техники
        дыхания и новый комплекс упражнений, который подходит для НОВИЧКОВ И
        ПРОДВИНУТЫХ. Ни один тренировка и ни один комплекс не повторяются, поэтому так
        важно участвовать в каждом - это системный подход к вашему телу!
      </p>

      <p>
        VIP тренировка - это быстрая трехдневная программа, на которой мы освоим
        методику #шпакметод_vip, основанную на задержке дыхания (чтобы небольшое
        кислородное голодание позволило нашему организму быстро избавиться от
        всего лишнего) и современных упражнений в динамике.
      </p>

      <p>
        Такой подход активизирует определенные биохимические процессы в теле,
        тем самым повышая усвоение жира организмом на энергетические цели и
        увеличивая уровень энергии. Весь комплекс занимает МАКСИМУМ 8 минут в
        день. Упражнения в нем направлены на сжигание жира, подтягивание кожи и
        проработку «проблемных зон» (живот, бёдра, талия, ноги и т.д.).
      </p>
    </div>
  );
  return (
    <>
      <TextSection content={content} />
    </>
  );
};

const chatMessages: ChatMessageType[] = [
  {
    text: "Можно ли заниматься #бодифлекс_с_алинойшпак / #оксисайз_с_алинойшпак во время беременности?",
  },
  {
    author: "Алина Шпак",
    text: "Нельзя",
  },
  {
    text: "Когда можно начинать заниматься после родов?",
  },
  {
    author: "Алина Шпак",
    text: "Если роды прошли без осложнений, начинать занятия можно через 4-6 недель. В этот период не жди быстрых результатов: пока гормональный фон не стабилизировался, похудеть достаточно сложно.",
  },
  {
    text: "Можно ли совмещать с другими тренировками?",
  },
  {
    author: "Алина Шпак",
    text: "УТРОМ ВСТАЛА, подышала, потом делай, что хочешь! Можно совмещать с другими фитнес-дисциплинами, но не заменяя, а лишь дополняя.",
  },
  {
    text: "Что делать, если во время трени кружится голова?",
  },
  {
    text: "Какие противопоказания?",
  },
  {
    author: "Алина Шпак",
    text: "Смотри на сайте в разделе ПРОТИВОПОКАЗАНИЯ! Перед началом тренировок проконсультируйся с врачом.",
  },
  {
    text: "Что делать, если после занятий вес стоит на месте или увеличивается?",
  },
  {
    author: "Алина Шпак",
    text: "Вес и объёмы это разные вещи. Мы ориентируемся НА РАЗМЕР ОДЕЖДЫ, т.е на ОБЪЁМЫ. Занимайся регулярно и выбери технику, эффективную именно для ТЕБЯ 😘",
  },
];

const liAgreeStyle =
  "before:content-['✅'] before:-left-6 before:text-xl before:absolute relative";

const howPass = (
  <div>
    <h2>За 3 дня в закрытой группе Telegram мы:</h2>
    <ul>
      <li className={liAgreeStyle}>Разберем технику дыхания</li>
      <li className={liAgreeStyle}>
        Отработаем быстрый интенсивный комплекс упражнений, который займет всего
        8 минут в день,
      </li>
    </ul>
    <ul>
      <li className={liAgreeStyle}>
        Научимся управлять своим уровнем энергии и повысим свою эффективность,
      </li>
    </ul>
    <ul>
      <li className={liAgreeStyle}>
        Выполним упражнения, направленные на биохакинг мозга для улучшения его
        деятельности и когнитивных способностей: памяти, концентрации,
        креативности, скорости решения задач и т.д.
      </li>
    </ul>

    <h3>Что нужно подготовить для тренировки:</h3>
    <ul>
      <li className={liAgreeStyle}>Коврик или большое полотенце для занятий;</li>
      <li className={liAgreeStyle}>
        Антицеллюлитный крем #мажьихудей;
      </li>
      <li className={liAgreeStyle}>
        Лук для тренировки - от «голышом» до спорт костюма, обувь не нужна
        (стирать каждый день!);
      </li>
      <li className={liAgreeStyle}>
        Найти комфортный уголок дома или вне. Рекомендуется выполнять упражнения
        перед зеркалом либо записывать себя на камеру, чтобы самостоятельно
        контролировать технику выполнения упражнений. Ваша жопа в ваших руках;
      </li>
      <li className={liAgreeStyle}>Маска для сна;</li>
    </ul>
  </div>
);

const liExlamationStyle =
  "before:content-['❗'] before:-left-6 before:text-xl before:absolute relative";

const rulesContent = (
  <div>
    <h2>Правила участия:</h2>
    <p>
      <strong>
        ШАГ 1. Оплата происходит через Robokassa на сайте ilovebodyflex.online.
      </strong>
    </p>
    <p>
      Внимательно указывайте свои личные данные в анкете на сайте (c
      неправильными данными обратная связь невозможна);
    </p>
    <p>
      <strong>
        ШАГ 2. После оплаты - вам нужно отправить запрос в закрытый Телеграм
        канал - именно там будет проходить тренировка.{" "}
        <a href="https://t.me/+ME1Gaop5TMk4OTYy">Перейти.</a>{" "}
      </strong>
    </p>
    <p>❗ Добавление в закрытый Телеграм канал, в течении 2-х дней.</p>
    <p>
      Если у вас возникли проблемы с добавлением или оплатой, вы можете написать
      в Телеграм <a href="https://t.me/shpakmetod">@shpakmetod</a> (техподдержка
      С 9:00-22:00)
    </p>
    <h2 className="block text-rose-500">Важно:</h2>
    <ul className="!list-none">
      <li className={liExlamationStyle}>
        После начала тренировки оплата не возвращается и не переносится ни по каким
        причинам;
      </li>
      <li className={liExlamationStyle}>
        Мой тренировка - мои правила! Что говорить, как рассуждать, темы лекции и все
        остальное на тренировке я определяю сама. Ваши советы и рекомендации просьба
        оставить при себе;
      </li>
      <li className={liExlamationStyle}>Скачайте приложение Telegram;</li>
      <li className={liExlamationStyle}>
        Заранее будьте уверены в вашем доступе к интернету, его скорости;
      </li>
      <li className={liExlamationStyle}>
        Ещё раз внимательно ознакомьтесь с противопоказаниями;
      </li>
      <li className={liExlamationStyle}>
        Копирование материла с тренировки, его распространение запрещено и
        преследуется законом;
      </li>
      <li className={liExlamationStyle}>Детальный разбор новых упражнений</li>
      <li className={liExlamationStyle}>
        Придерживаемся принципа персональной ответственности за свое здоровье.
      </li>
    </ul>
  </div>
);

const testimonialsText = (
  <div>
    <h2>Отзывы моих королев</h2>
    <p>Школа Алины Шпак настроена на результат</p>
    <p>
      <span className="font-bold text-rose-500">#королевыбодифлекса</span>{" "}
      начинают выкидывать и раздавать одежду, которая им велика. Когда, они едят
      то, что им нравится и живут в кайф. А потом пишут мне, что худеют не
      смотря ни на что. Когда возвращаются с отпусков меньше на размер. Когда не
      парятся, что б такого съесть, чтоб похудеть
    </p>
    <p>
      Потому что <span className="font-bold text-rose-500">#шпакметод</span>{" "}
      работает на них. На их мировосприятие, тело и РЕЗУЛЬТАТ. Зачем сюсюканья,
      если это не приблизит вас к телу без жира?
    </p>
  </div>
);

const liRestrictedStyle =
  "before:content-['❌'] before:-left-6 before:text-xl before:absolute relative";

const contraindication = (
  <div>
    <h2>Противопоказания</h2>
    <ul>
      <li className={liRestrictedStyle}> Беременность 🤰</li>
      <li className={liRestrictedStyle}> Язва желудка (в период обострения)</li>
      <li className={liRestrictedStyle}>
        Любые заболевания сердца: сердечная недостаточность, аритмия,
        тахикардия, перенесенный инфаркт, ишемическая болезнь сердца и т.п.
      </li>
      <li className={liRestrictedStyle}> Эпилепсия</li>
      <li className={liRestrictedStyle}> Диафрагмальная грыжа</li>
    </ul>
  </div>
);

const products = [
  {
    id: 36,
    name: "VIP тренировка",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытому Телеграм каналу на 21 день, в котором проходит
          тренировка. &nbsp;<a href="https://t.me/+ME1Gaop5TMk4OTYy">Перейти.</a>
        </li>
        <li className="font-bold">
          Проверка всех домашних заданий в общем vip чате в WhatsApp
        </li>
        <li className="font-bold">Жесткий режим и тотальный контроль</li>
        <li className="font-bold text-rose-500">Обращаем ваше внимание, что вам необходимо САМОСТОЯТЕЛЬНО отправить запрос в группу тренировки.</li>
        <li className="font-bold">Внимательно указывайте ваши данные, при заполнении анкеты.
        </li>

      </ul>
    ),
    // oldPrice: "12 000 ₽",
    price: "12 000 ₽",
  },
];

const byAgreementWithTheDoctor = (
  <div className="pb-12">
    <h2>По согласованию с врачом:</h2>
    <ul className="!list-none">
      <li className={liRestrictedStyle}> Гипертония 2 и 3 степени</li>
      <li className={liRestrictedStyle}>Нефрэктомия (удаленная почка)</li>
      <li className={liRestrictedStyle}>
        Серьезные проблемы со зрением (высокая степень близорукости, глаукома и
        т.п.)
      </li>
      <li className={liRestrictedStyle}>
        Любые заболевания сосудов, слабость сосудов (о слабости сосудов
        свидетельствует постоянное возникновение синяков, гематом, «лопание»
        сосудов, например, сосудов глаза, носовые кровотечения), проблемы с
        сосудами головного мозга
      </li>
      <li className={liRestrictedStyle}>Киста (локализация в яичнике)</li>
      <li className={liRestrictedStyle}>
        Мочекаменная и желчекаменная болезнь (в период обострения)
      </li>
      <li className={liRestrictedStyle}>Травмы и грыжи позвоночника</li>
    </ul>
    <div className="mt-10">
      <ConsultationSVG className="w-full" />
    </div>
  </div>
);

export const Vip = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const introRef = useRef<HTMLDivElement>(null);
  const qaRef = useRef<HTMLDivElement>(null);
  const howRef = useRef<HTMLDivElement>(null);
  const rulesRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const contraindicationRef = useRef<HTMLDivElement>(null);
  const buyRef = useRef<HTMLDivElement>(null);

  const scrollTo = (name: string) => {
    if (name === "top") window.scrollTo(0, introRef?.current?.offsetTop || 0);
    if (name === "intro") window.scrollTo(0, introRef?.current?.offsetTop || 0);
    if (name === "qa") window?.scrollTo(0, qaRef?.current?.offsetTop || 0);
    if (name === "how") window?.scrollTo(0, howRef?.current?.offsetTop || 0);
    if (name === "rules")
      window?.scrollTo(0, rulesRef?.current?.offsetTop || 0);
    if (name === "testimonials")
      window?.scrollTo(0, reviewsRef?.current?.offsetTop || 0);
    if (name === "contraindication")
      window?.scrollTo(0, contraindicationRef?.current?.offsetTop || 0);
    if (name === "buyRef") window?.scrollTo(0, buyRef?.current?.offsetTop || 0);
  };

  return (
    <div ref={topRef}>
      <Header scrollTo={scrollTo} ref={headerRef} />
      <HeroVip />
      <div ref={introRef} />
      <AboutSection />
      <div ref={qaRef} />
      <HeroImageContainer imageName="qa.png" />
      <ChatMessages messages={chatMessages} />
      <div ref={howRef} />
      <HeroImageContainer imageName="how-pass.png" />
      <TextSection content={howPass} />
      <div ref={rulesRef} />
      <HeroImageContainer imageName="rules.png" />
      <TextSection content={rulesContent} />
      <div ref={reviewsRef} />
      <HeroImageContainer imageName="testimonials.png" />
      <TextSection content={testimonialsText} />
      <TextSection content={contraindication} />
      <div ref={contraindicationRef} />
      <TextSection
        className="bg-gray-100 pt-4"
        content={byAgreementWithTheDoctor}
      />
      <div ref={buyRef} />
      <Products date={"2 февраля 2024"} products={products} closed={false} />
      <Footer />
    </div>
  );
};
