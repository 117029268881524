import { ReactComponent as TitleProFirst } from "assets/svg/header-pro-title-first.svg";
import { ReactComponent as TitleProSecond } from "assets/svg/header-charger.svg";
import { useRef } from "react";
import { Footer } from "components/Footer";
import { TextSection } from "components/TextSection";
import Carousel from "nuka-carousel";

import { ReactComponent as StomachSVG } from "assets/content/results/charger/stomach.svg";
import { ReactComponent as DropSVG } from "assets/content/results/charger/drop.svg";
import { ReactComponent as StrongSVG } from "assets/content/results/charger/strong.svg";
import { ReactComponent as HeartSVG } from "assets/content/results/charger/heart.svg";
import { ReactComponent as BrainSVG } from "assets/content/results/charger/brain.svg";
import { ReactComponent as ImmunitySVG } from "assets/content/results/charger/immunity.svg";
import { ReactComponent as EighteenSVG } from "assets/content/results/charger/18.svg";
import { ReactComponent as ConsultationSVG } from "assets/svg/consultation.svg";

import before1 from "assets/content/beforeafter/1.jpg";
import before2 from "assets/content/beforeafter/2.jpg";
import before3 from "assets/content/beforeafter/3.jpg";
import before4 from "assets/content/beforeafter/4.jpg";
import before5 from "assets/content/beforeafter/5.jpg";
import before6 from "assets/content/beforeafter/6.jpg";
// import { Contraindications } from "components/Contraindications";
import { Products } from "components/Products";
import { HeaderCharger } from "components/HeaderCharger";

const HeroSection = ({ scrollTo }: { scrollTo: (name: string) => void }) => {
  return (
    <>
      <div className="relative bg-red-100 lg:hidden">
        <div className="clip-hero-bg-white relative h-screen bg-white sm:flex">
          <div className="clip-hero-bg-mobile absolute flex h-full w-full bg-heroBgMobile bg-cover bg-center bg-no-repeat"></div>
        </div>
        <div className="absolute bottom-6 flex h-1/2 w-full flex-col content-center items-center justify-end font-ristretto">
          <div className="text-red-500 font-semibold text-8xl leading-none">#ШпакМетод</div><div className="text-cyan-600 font-semibold text-8xl leading-none">_Зарядка</div>
          <div
            onClick={() => scrollTo("buy")}
            className="text-xl mt-1 w-52 cursor-pointer rounded-md bg-red-500 p-3 text-center uppercase text-white"
          >
            Купить
          </div>
        </div>
      </div>
      <div className="hidden h-screen w-auto bg-rose-200 md:hidden lg:visible lg:flex">
        <div className="clip-header-white relative flex w-2/5 bg-white bg-cover bg-center bg-no-repeat">
          <div className="clip-header z-10 flex w-full bg-red-100 bg-alinaLay bg-cover bg-center bg-no-repeat"></div>
        </div>
        <div className="mx-auto flex	w-1/2 flex-wrap content-center items-center justify-center gap-8">
          <div className="mr-4 mt-8 flex flex-grow-0 gap-2 font-ristretto">
            <div className="text-red-500 font-semibold text-8xl leading-none">#ШпакМетод</div><div className="text-cyan-600 font-semibold text-8xl leading-none">_Зарядка</div>
          </div>
          <div
            onClick={() => scrollTo("buy")}
            className="text-xl mt-1 w-52 cursor-pointer rounded-md bg-red-500 p-3 text-center uppercase text-white"
          >
            Купить
          </div>
        </div>
      </div>
    </>
  );
};

const About = () => {
  const content = (
    <>
      <h1>
        Тренировка <span className="text-rose-500">#ШпакМетод Зарядка</span>
      </h1>
      <p>
        <strong>
          Тренировка #ШпакМетод Зарядка рекомендована к прохождению всем новеньким. C
          неё начинается путь королевы и знакомство с методом Алины Шпак. Это
          база.
        </strong>
      </p>
      <p>
        Шпакметод_зарядка – МЕНТАЛЬНЫЙ ФИТНЕС, ежедневная дыхательная
        тренировка, которая помогает укрепить мышцы и сжечь жир с помощью
        кислорода{" "}
        <strong>
          за 10 минут в день не выходя из дома БЕЗ ДИЕТ И ОГРАНИЧЕНИЙ.
        </strong>{" "}
        Идеальный вариант активной медитации, которая поддерживает отличную
        форму тела, увеличивает уровень энергии и иммунитета, даёт настрой на
        весь день.
      </p>
      <p>
        Также в базовую тренировку мы добавили АДАПТИРОВАННУЮ ТРЕНИРОВКУ НА ВРЕМЯ
        БЕРЕМЕННОСТИ, чтобы вы могли заниматься дыханием всегда и вечернюю 3-х
        минутную тренировку на расслабление{" "}
        <span className="text-rose-500">#шпакметод_разрядка</span>
      </p>
    </>
  );
  return <TextSection content={content} />;
};

const Results = () => {
  return (
    <div className="w-full bg-gray-100 pb-20">
      <div className="prose-lg mx-auto w-full pt-20 pb-10 text-center font-serif font-bold">
        <h1 className="">
          Результаты от техники <wbr /> #ШпакМетод Зарядка:
        </h1>
      </div>
      <div className="flex flex-wrap content-center  justify-center">
        <div className="flex h-56 w-56 flex-col items-center p-2 text-center">
          <div className="flex h-32 w-32 shrink-0 content-center items-center justify-center">
            <DropSVG />
          </div>
          <div className="text-xs flex p-2 text-center font-serif">
            Избавляет от целлюлита, убирает отеки, подтягивает тело
          </div>
        </div>
        <div className="flex h-56 w-56 flex-col items-center p-2 text-center">
          <div className="flex h-32 w-32 shrink-0 content-center items-center justify-center">
            <StrongSVG />
          </div>
          <div className="text-xs flex p-2 text-center font-serif">
            Укрепляет мышцы тела
          </div>
        </div>
        <div className="flex h-56 w-56 flex-col items-center p-2 text-center">
          <div className="flex h-32 w-32 content-center items-center justify-center">
            <StomachSVG />
          </div>
          <div className="text-xs flex p-2 text-center font-serif">
            Уменьшает желудок без медицинского вмешательства
          </div>
        </div>{" "}
        <div className="flex h-56 w-56 flex-col items-center p-2 text-center">
          <div className="flex h-32 w-32 content-center items-center justify-center">
            <HeartSVG />
          </div>
          <div className="text-xs flex p-2 text-center font-serif">
            Улучшает здоровье и общее самочувствие
          </div>
        </div>{" "}
        <div className="flex h-56 w-56 flex-col items-center p-2 text-center">
          <div className="flex h-32 w-32 content-center items-center justify-center">
            <BrainSVG />
          </div>
          <div className="text-xs flex p-2 text-center font-serif">
            Нормализует эмоциональный фон и “чистит” голову
          </div>
        </div>{" "}
        <div className="flex h-56 w-56 flex-col items-center p-2 text-center">
          <div className="flex h-32 w-32 content-center items-center justify-center">
            <EighteenSVG />
          </div>
          <div className="text-xs flex p-2 text-center font-serif">
            Укрепляет интимные мышцы, повышает либидо
          </div>
        </div>
        <div className="flex h-56 w-56 flex-col items-center p-2 text-center">
          <div className="flex h-32 w-32 content-center items-center justify-center">
            <ImmunitySVG />
          </div>
          <div className="text-xs flex p-2 text-center font-serif">
            Укрепляет иммунитет
          </div>
        </div>
      </div>
    </div>
  );
};

const liAgreeStyle =
  "before:content-['✅'] before:-left-6 before:text-xl before:absolute relative";

const liExlamationStyle =
  "before:content-['❗'] before:-left-6 before:text-xl before:absolute relative";

const HowItGoes = () => {
  const howPass = (
    <div>
      <h2>Как проходит тренировка?</h2>
      <ul>
        <li className={liAgreeStyle}>
          В день оплаты, в течение 24 часов, на ваш номер телефона пришлют
          ссылку на закрытый Телеграм канал, именно там проходит тренировка.
        </li>
        <li className={liAgreeStyle}>
          После того как вас добавили в закрытый телеграм канал, вы получаете
          доступ к урокам - техника дыхания, разборы упражнений, тренировки.
          Плюс 3 урока теории от Алины Шпак. Вы можете смотреть их в удобное для
          вас время. Доступ к информации до 1 января 2025 года
        </li>
      </ul>
      <h3>Что нужно подготовить для тренировки:</h3>
      <ul>
        <li className={liAgreeStyle}>
          Коврик или большое полотенце, свободную стенку
        </li>
      </ul>
      <ul>
        <li className={liAgreeStyle}>
          Антицеллюлитный крем, крем для груди, маски для лица, патчи по желанию
        </li>
      </ul>
    </div>
  );
  return <TextSection content={howPass} />;
};

const BeforeAndAfter = () => {
  return (
    <div className="prose mx-auto mt-10 flex flex-col content-center items-center justify-center font-serif">
      <h2>Отзывы моих королев</h2>
      <Carousel
        defaultControlsConfig={{
          nextButtonText: ">",
          prevButtonText: "<",
          pagingDotsStyle: {
            fill: "red",
          },
        }}
      >
        <img src={before1} alt="До и После" />
        <img src={before2} alt="До и После" />
        <img src={before3} alt="До и После" />
        <img src={before4} alt="До и После" />
        <img src={before5} alt="До и После" />
        <img src={before6} alt="До и После" />
      </Carousel>
    </div>
  );
};

const Rules = () => {
  const rulesContent = (
    <div>
      <h2>Правила участия:</h2>
      <p>
        <strong>
          ШАГ 1. Оплата происходит через Robokassa на сайте
          ilovebodyflex.online.
        </strong>
      </p>
      <p>
        Внимательно указывайте свои личные данные в анкете на сайте (с
        неправильными данными обратная связь невозможна);
      </p>
      <p>
        <strong>
          ШАГ 2. После оплаты, на ваш номер телефона в течение 24 часов, пришлют
          ссылку на Telegram канал, в котором проходит тренировка.
        </strong>
      </p>
      <p>
        Если у вас возникли проблемы с добавлением или оплатой, вы можете
        написать в Телеграм <a href="https://t.me/shpakmetod">@shpakmetod</a>
      </p>{" "}
      (техподдержка С 9:00-22:00)
      <p>
        <strong>ШАГ 3. Вы уже похудели! 😇</strong>
      </p>
      <h2 className="block text-rose-500">Важно:</h2>
      <ul className="list-none">
        <li className={liExlamationStyle}>
          После начала тренировки оплата не возвращается и не переносится ни по каким
          причинам;
        </li>
        <li className={liExlamationStyle}>
          Моя тренировка - мои правила! Что говорить, как рассуждать, темы лекции и
          всё остальное на тренировке я определяю сама. Ваши советы и рекомендации
          просьба оставить при себе;
        </li>
        <li className={liExlamationStyle}>Скачайте приложение Telegram;</li>
        <li className={liExlamationStyle}>
          Заранее будьте уверены в вашем доступе к интернету, его скорости;
        </li>
        <li className={liExlamationStyle}>
          Ещё раз внимательно ознакомьтесь с противопоказаниями;
        </li>
        <li className={liExlamationStyle}>
          Копирование материала с тренировки, его распространение запрещено и
          преследуется законом;
        </li>
        <li className={liExlamationStyle}>Детальный разбор новых упражнений</li>
        <li className={liExlamationStyle}>
          Придерживаемся принципа персональной ответственности за своё здоровье.
        </li>
        <li className={liExlamationStyle}>
          Следует учесть, что людям с проблемами позвоночника, гипертонией,
          пупочной или паховой грыжей, а также людям в послеоперационный период
          до трёх месяцев Шпакметод зарядка ПРОТИВОПОКАЗАНА. Поэтому
          предварительно стоит проконсультироваться с врачом.
        </li>
        <li className={liExlamationStyle}>
          СЛЕДИ ЗА СВОИМ САМОЧУВСТВИЕМ. При любом дискомфорте прекрати
          тренировку! (Лёгкое головокружение во время первых нескольких дней
          нормально)
        </li>
        <li className={liExlamationStyle}>
          Теперь что касается беременных. Им также можно делать этот комплекс,
          но после консультации с врачом; исключая упражнения на животе,
          исключая втягивание живота на выдохе.
        </li>
        <li className={liExlamationStyle}>
          Покупая тренировку вы подтверждаете что вы достаточно владеете русским
          языком письменным и устным ДЛЯ ПОНИМАНИЯ ВСЕГО РАНЕЕ УКАЗАННОГО НА
          САЙТЕ и того что будет на тренировке.
        </li>
      </ul>
    </div>
  );
  return <TextSection content={rulesContent} />;
};

const products = [
  {
    id: 14,
    name: "Базовая тренировка",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытой группе telegram
        </li>
        <li className="font-bold">Запись видео уроков с доступом до 1 января 2025 года</li>
      </ul>
    ),
    price: "5 225 ₽",
    oldPrice: "10 000 ₽",
  },
];

const liRestrictedStyle =
  "before:content-['❌'] before:-left-6 before:text-xl before:absolute relative";

const contraindication = (
  <div>
    <h2>Противопоказания</h2>
    <h4>Основные противопоказания к #ШпакМетод Зарядка:</h4>
    <ul className="list-none">
      <li className={liRestrictedStyle}> Беременность 🤰</li>
      <li className={liRestrictedStyle}>Язва желудка (в период обострения)</li>
      <li className={liRestrictedStyle}>
        Любые заболевания сердца: сердечная недостаточность, аритмия,
        тахикардия, перенесённый инфаркт, ишемическая болезнь сердца и т.п.
      </li>
      <li className={liRestrictedStyle}>
        Диафрагмальная грыжа, грыжа пищевода
      </li>
      <li className={liRestrictedStyle}>Обострение хронических заболеваний</li>
      <li className={liRestrictedStyle}>
        Послеоперационный период (занятия только после разрешения лечащего
        врача)
      </li>
      <li className={liRestrictedStyle}>Киста (яичник)</li>
    </ul>
    <div className="mt-10">
      <ConsultationSVG className="w-full" />
    </div>
  </div>
);

export const Charger = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const qaRef = useRef<HTMLDivElement>(null);
  const howItGoesRef = useRef<HTMLDivElement>(null);
  const rulesRef = useRef<HTMLDivElement>(null);
  const testimonialsRef = useRef<HTMLDivElement>(null);
  const contraindicationRef = useRef<HTMLDivElement>(null);
  const buyRef = useRef<HTMLDivElement>(null);
  const howToPassImg = require("assets/content/how-pass-course.png");
  const rules = require("assets/content/rules.png");
  const testimonials = require("assets/content/testimonials.png");

  const scrollTo = (name: string) => {
    if (name === "about") window.scrollTo(0, aboutRef?.current?.offsetTop || 0);
    if (name === "results")
      window.scrollTo(0, resultsRef?.current?.offsetTop || 0);
    if (name === "qa") window?.scrollTo(0, qaRef?.current?.offsetTop || 0);
    if (name === "howItGoes")
      window?.scrollTo(0, howItGoesRef?.current?.offsetTop || 0);
    if (name === "rules")
      window?.scrollTo(0, rulesRef?.current?.offsetTop || 0);
    if (name === "testimonials")
      window?.scrollTo(0, testimonialsRef?.current?.offsetTop || 0);
    if (name === "contraindication")
      window?.scrollTo(0, contraindicationRef?.current?.offsetTop || 0);
    if (name === "buy") window?.scrollTo(0, buyRef?.current?.offsetTop || 0);
  };
  return (
    <>
      <HeaderCharger scrollTo={scrollTo} />
      <HeroSection scrollTo={scrollTo} />
      <div ref={aboutRef} />
      <About />
      <div ref={resultsRef} />
      <Results />
      <div ref={howItGoesRef} />
      <img
        src={howToPassImg}
        alt="Как проходит тренировка?"
        className="pointer-events-none"
      />
      <HowItGoes />
      <div ref={rulesRef} />
      <img src={rules} alt="Правила" className="pointer-events-none" />
      <Rules />
      <div ref={testimonialsRef} />
      <img src={testimonials} alt="Отзывы" className="pointer-events-none" />
      <BeforeAndAfter />
      {/* <div ref={contraindicationRef} />
      <TextSection
        className="bg-gray-100 pt-4"
        margin="mt-0"
        content={contraindication}
      /> */}
      <TextSection
        content={
          <p>
            Проще говоря, цитирую своих Королев: «Алина, твои тренировки - лучшее
            вложение в себя в своё будущее». И это 100% истина, так как мы
            прокачиваем не только своё тело, но и мозги!
          </p>
        }
      />
      <div className="p-4" />
      <div ref={buyRef} />
      <Products products={products} />
      <Footer />
    </>
  );
};
