import { ChatMessages } from "components/ChatMessages";
import { ChatMessageType } from "components/ChatMessages/ChatMessages";
import { Testimonials } from "components/Testimonials";
import { TestimonialType } from "components/Testimonials/Testimonials";
import { TextSection } from "components/TextSection";
import { HeroImageContainer } from "components/HeroImageContainer";
import { ReactComponent as BodySVG } from "assets/content/results/body.svg";
import { ReactComponent as ImmuneVG } from "assets/content/results/immune.svg";
import { ReactComponent as CycleSVG } from "assets/content/results/cycle.svg";
import { ReactComponent as ForkAndSpoonSVG } from "assets/content/results/fork_spoon.svg";
import { ReactComponent as BackSideSVG } from "assets/content/results/back_side.svg";
import { ReactComponent as StomachSVG } from "assets/content/results/stomach.svg";
import { ReactComponent as YogaSVG } from "assets/content/results/yoga.svg";
import { ReactComponent as FaceSVG } from "assets/content/results/face.svg";
import { ReactComponent as ConsultationSVG } from "assets/svg/consultation.svg";
import { ReactComponent as TitleBodyflex } from "assets/content/title-bodyflex.svg";
import { ReactComponent as TitleSecond } from "assets/content/title-second.svg";
import { Header } from "components/Header";
import { useRef } from "react";
import { Products } from "components/Products";
import { Footer } from "components/Footer";

const intro = (
  <>
    {/* <h1 className="font-bold text-5xl">
      Одна цель — сделать из вас{" "}
      <span className="text-rose-500 block">@хуенных королев</span>
    </h1> */}
    <p>
      Каждая тренировка с Алиной Шпак уникальна, каждый раз - это разные техники
      дыхания и новый комплекс упражнений, который подходит для НОВИЧКОВ И
      ПРОДВИНУТЫХ. Ни одна тренировка и ни один комплекс не повторяются, поэтому
      так важно участвовать в каждой - это системный подход к вашему телу!
    </p>
    <p>
      <strong>
        Бодифлекс- это самая агрессивная техника дыхания, сочетает в себе
        вакуумное дыхание (диафрагмальное дыхание с задержкой дыхания), и
        упражнения на разные группы мышц, уникальная методика помогает сжечь
        жир, массировать внутренние органы и одновременно тонизировать все мышцы
        тела. И это всего 15 минут в день.
      </strong>
    </p>
  </>
);

const chatMessages: ChatMessageType[] = [
  {
    text: "Можно ли заниматься #бодифлекс_с_алинойшпак / #оксисайз_с_алинойшпак во время беременности?",
  },
  {
    author: "Алина Шпак",
    text: "Нельзя",
  },
  {
    text: "Когда можно начинать заниматься после родов?",
  },
  {
    author: "Алина Шпак",
    text: "Если роды прошли без осложнений, начинать занятия можно через 4-6 недель. В этот период не жди быстрых результатов: пока гормональный фон не стабилизировался, похудеть достаточно сложно.",
  },
  {
    text: "Можно ли совмещать с другими тренировками?",
  },
  {
    author: "Алина Шпак",
    text: "УТРОМ ВСТАЛА, подышала, потом делай, что хочешь! Можно совмещать с другими фитнес-дисциплинами, но не заменяя, а лишь дополняя.",
  },
  {
    text: "Что делать, если во время трени кружится голова?",
  },
  {
    text: "Какие противопоказания?",
  },
  {
    author: "Алина Шпак",
    text: "Смотри на сайте в разделе ПРОТИВОПОКАЗАНИЯ! Перед началом тренировок проконсультируйся с врачом.",
  },
  {
    text: "Что делать, если после занятий вес стоит на месте или увеличивается?",
  },
  {
    author: "Алина Шпак",
    text: "Вес и объёмы это разные вещи. Мы ориентируемся НА РАЗМЕР ОДЕЖДЫ, т.е на ОБЪЁМЫ. Занимайся регулярно и выбери технику, эффективную именно для ТЕБЯ 😘",
  },
];

const liAgreeStyle =
  "before:content-['✅'] before:-left-6 before:text-xl before:absolute relative";

const howPass = (
  <div>
    <h2>
      7 дней каждое утро в 8:00 МСК выходит урок, который состоит из 3 частей:
    </h2>
    <ul>
      <li className={liAgreeStyle}>Детальный разбор новых упражнений</li>
      <li className={liAgreeStyle}>Тренировка</li>
    </ul>
    <ul>
      <li className={liAgreeStyle}>
        Доступ к информации сохраняется на 2 недели со дня начала тренировки.
      </li>
      <li className={liAgreeStyle}>
        В ПЕРВЫЙ ДЕНЬ тренировки мы подробно разбираем технику дыхания.
      </li>
      <li className={liAgreeStyle}>
        лекции от дерматолога-косметолога с медицинским стажем 27 лет, с темой:
        секреты вечной молодости, Anti-Age терапия
      </li>
      <li className={liAgreeStyle}>Практика от Алины Шпак</li>
    </ul>
    <h3>Что нужно подготовить для тренировки:</h3>
    <ul>
      <li className={liAgreeStyle}>Коврик или большое полотенце для занятий</li>
      <li className={liAgreeStyle}>
        Антицеллюлитный крем, крем для груди, маски для лица, патчи по желанию
      </li>
      <li className={liAgreeStyle}>
        Лук для тренировки - от «голышом» до спорт костюма, обувь не нужна.
        (стирать каждый день!)
      </li>
      <li className={liAgreeStyle}>
        Найти комфортный уголок дома или вне. Рекомендуется выполнять упражнения
        перед зеркалом либо записывать себя на камеру, чтобы самостоятельно
        контролировать технику выполнения упражнений. Ваша жопа в ваших руках.
      </li>
    </ul>
  </div>
);

const liExlamationStyle =
  "before:content-['❗'] before:-left-6 before:text-xl before:absolute relative";

const rulesContent = (
  <div>
    <h2>Правила участия:</h2>
    <p>
      <strong>
        ШАГ 1. Оплата происходит через Robokassa на сайте ilovebodyflex.online.
      </strong>
    </p>

    <p>
      Внимательно указывайте свои личные данные в анкете на сайте (c
      неправильными данными обратная связь невозможна);
    </p>
    <p>
      <strong>
        ШАГ 2. После оплаты - вам нужно отправить запрос в закрытый Телеграм
        канал - именно там будет проходить тренировка.{" "}
        <a href="https://t.me/+Xtc9CVJ4xHcwNTIy">Перейти.</a>{" "}
      </strong>
    </p>
    <p>❗ Добавление в закрытый Телеграм канал, в течении 2-х дней.</p>
    <p>
      Если у вас возникли проблемы с добавлением или оплатой, вы можете написать
      в Телеграм <a href="https://t.me/shpakmetod">@shpakmetod</a> (техподдержка
      С 9:00-22:00)
    </p>
    <h2 className="text-rose-500 block">Важно:</h2>
    <ul className="!list-none">
      <li className={liExlamationStyle}>
        После начала тренировки оплата не возвращается и не переносится ни по
        каким причинам;
      </li>
      <li className={liExlamationStyle}>
        Моя тренировка - мои правила! Что говорить, как рассуждать, темы лекции
        и все остальное на тренировке я определяю сама. Ваши советы и
        рекомендации просьба оставить при себе;
      </li>
      <li className={liExlamationStyle}>Скачайте приложение Telegram;</li>
      <li className={liExlamationStyle}>
        Заранее будьте уверены в вашем доступе к интернету, его скорости;
      </li>
      <li className={liExlamationStyle}>
        Ещё раз внимательно ознакомьтесь с противопоказаниями;
      </li>
      <li className={liExlamationStyle}>
        Копирование материла с тренировки, его распространение запрещено и
        преследуется законом;
      </li>
      <li className={liExlamationStyle}>Детальный разбор новых упражнений</li>
      <li className={liExlamationStyle}>
        Придерживаемся принципа персональной ответственности за свое здоровье.
      </li>
    </ul>
  </div>
);

const testimonialsText = (
  <div>
    <h2>Отзывы моих королев</h2>
    <p>Школа Алины Шпак настроена на результат</p>
    <p>
      <span className="text-rose-500 font-bold">#королевыбодифлекса</span>{" "}
      начинают выкидывать и раздавать одежду, которая им велика. Когда, они едят
      то, что им нравится и живут в кайф. А потом пишут мне, что худеют не
      смотря ни на что. Когда возвращаются с отпусков меньше на размер. Когда не
      парятся, что б такого съесть, чтоб похудеть
    </p>
    <p>
      Потому что <span className="text-rose-500 font-bold">#шпакметод</span>{" "}
      работает на них. На их мировосприятие, тело и РЕЗУЛЬТАТ. Зачем сюсюканья,
      если это не приблизит вас к телу без жира?
    </p>
  </div>
);

const testimonials: TestimonialType[] = [
  {
    name: "daryadoshenko",
    avatar: "review-avatar-1.png",
    text: (
      <p>
        👍легко и очень просто, главное делать правильно) ❤️мне очень нравится)
        буду ждать теперь эфир с техникой пожёстче)😁 я после зарядки как
        пропеллер ношусь ...) 😘 благодарю
      </p>
    ),
    likes: 27,
  },
  {
    name: "smartcosmetolog",
    avatar: "ava-8.jpeg",
    text: (
      <p>
        Я начала с Алиной работать в августе: я маленькая и поэтому каждый кг
        виден 🙈 к сегодняшнему дню -3,5 кг 💪 в объёмах только под грудью -8 см
        🔥🔥🔥 Теперь у меня задача ещё -4 кг с оксисайз 👌 Мне нравится подход
        Алины: всё просто, делайте, как говорю, главное каждый день))) Реально
        всё работает — первый результат виден через несколько дней 💪 Это
        ГЕНИАЛЬНАЯ СИСТЕМА 🥇"
      </p>
    ),
    likes: 159,
  },
  {
    name: "katri_na93",
    avatar: "ava-2.jpeg",
    text: (
      <p>
        Не так-то просто уложить всю благодарность в одно сообщение 😍 началось
        с того, что после родов, я сильно поправилась, хоть кг и показывали
        примерно такие же цифры как до беременности, но объемы были просто
        огромными! Пол года я наблюдала за Вашей страничкой, сначала одним
        глазом, потом ждала, когда начнётся тренировка! Я заранее настраивала
        себя на плохой результат, ведь маленький ручной ребёнок весь день со
        мной, да и сил не было совсем, пока малышка спала, я тоже спала, и ещё
        безумно спина болела, дочку не могла поднимать, если только со слезами.
        После тренировки, кстати, собиралась к остеопату насчёт спины. Вот
        начался тренировка, нашла в себе силы не спать, а смотреть эфир, делала
        все с опозданием на один день. А дальше началось нечто... на третий день
        тренировки я проснулась без боли в спине... пишу и прям плачу... я на
        протяжении нескольких месяцев не могла даже лежать без боли, а тут
        такое...
      </p>
    ),
    likes: 137,
  },
  {
    name: "alinkashpak",
    avatar: "ava-1.jpeg",
    text: (
      <p>
        100% работает, а фраза «Ваша жопа в ваших рука» спасёт мир!!! Алина, не
        перестаю вас благодарить, жир тает, а энергия только прибавляется,
        участвовала уже в 2-х тренировках и жду 3-й!!! ❤️❤️❤️😘
      </p>
    ),
    likes: 152,
  },
  {
    name: "la_skava",
    avatar: "ava-3.jpeg",
    text: (
      <p>
        Алииииииинааа! Это невероятно!!! Я в шоке, поэтому ступор в словах моей
        благодарности!!! Но! Три недели моего первого тренировки с тобой и
        тааакиие результаты!!! Грудь без изменений! Талия минус 4 см, живот
        минус 3, бёдра минус 2!!!! А ноги! Ноги!!! Я уже забыла какой они формы,
        мои прекрасные длинные ноги!! Постоянно отечные и жирноватые((( При
        росте 175 и весе 68. Сейчас объёмы 93-74-100!!!! Я навеки с Вами! Всё
        работает!!
      </p>
    ),
    likes: 152,
  },
  {
    name: "talkree78",
    avatar: "ava-4.jpeg",
    text: (
      <p>
        Привет дышу с тобой уже Неделю и не знаю или это от головы или реально
        всё так классно, живот мой уходит, хотя я пью пиво в выхи и не могу
        забыть про него, но уже не утро без дыхания, как наркотик. Встаю и бегом
        дышать с мелкой, она за мной всё повторяет 😊 Надеюсь скоро стать худой
        и подтянутой 😊 😛
      </p>
    ),
    likes: 189,
  },
  {
    name: "tan_tan",
    avatar: "ava-5.jpeg",
    text: (
      <p>
        Алина, благодарю за тренировку ❤️ Более понятного объяснения техники и
        упражнений я не слышала нигде ещё ✊ У меня тариф без обратной связи, но
        я считаю, что всё более, чем понятно 👌👌👌 Подписана давно на тебя,
        пробовала делать и #шпакметод_зарядка и упражнения в свободном доступе
        по бодифлексу, думала всё уже знаю ✌️ такая деловая, хрен там 🤚🙈🙊 В
        тренировке я совершенно по-другому начала дышать, более осознанно все 5
        этапов, прям супер 💫 Помогают твои объяснения деталей мелких, вроде как
        итак понятно, но вот именно там подкрутил, тут голову наклонил и всё,
        совсем другое дело, совсем другой результат, и живот лучше уходит под
        рёбра и на пятом этапе хочется вдохнуть как положено, а то ж когда
        самостоятельно делала, думала, ну что тяжёлого-то, задержать на 10 сек.,
        фи, я могу и дольше, ага, сейчас до 8-9 секунд дотянуть бы 😐 В общем, я
        в восторге полнейшем 👏 Хочу теперь подышать с тобой оффлайн 💫💫💫
      </p>
    ),
    likes: 189,
  },
  {
    name: "nenris77",
    avatar: "ava-6.jpeg",
    text: (
      <p>
        И вот сейчас: качество тела изменилось, целлюлит ушёл, влезла во все
        вещи. Работы ещё немного и я навеки Ваша... 💙💙💙 пью вино и ем всё,
        что хочу, дышу по утрам и слушаю чашу... доверяю и верю и дальше
        наблюдаю... как изменится моё тело, как изменюсь я и всё вокруг...
        дыхательные техники — это не только про жопу, это про всю жизнь... я
        выдохнула благодаря Вам Спасибо 🙏
      </p>
    ),
    likes: 189,
  },
  {
    name: "tatkach66",
    avatar: "ava-7.jpeg",
    text: (
      <p>
        Тренировка!! Просто супер! Мне очень нравится! Бодифлекс тоже интересен!
        Но оксисайз просто 🔥 !!! Результаты лично у меня более ощутимее!! Всё
        тело ломит от мышечной боли. Очень люблю такое состояние. Спасибо
        огромное!! Ждём продолжения!
      </p>
    ),
    likes: 189,
  },
];
const liRestrictedStyle =
  "before:content-['❌'] before:-left-6 before:text-xl before:absolute relative";

const contraindication = (
  <div>
    <h2>Противопоказания</h2>
    <h4>Основные противопоказания к #Бодифлекс_c_АлинойШпак:</h4>
    <ul>
      <li className={liRestrictedStyle}> Беременность 🤰</li>
      <li className={liRestrictedStyle}> Язва желудка (в период обострения)</li>
      <li className={liRestrictedStyle}>
        Любые заболевания сердца: сердечная недостаточность, аритмия,
        тахикардия, перенесенный инфаркт, ишемическая болезнь сердца и т.п.
      </li>
      <li className={liRestrictedStyle}> Эпилепсия</li>
      <li className={liRestrictedStyle}> Диафрагмальная грыжа</li>
    </ul>
  </div>
);

const byAgreementWithTheDoctor = (
  <div className="pb-12">
    <h2>По согласованию с врачом:</h2>
    <ul className="!list-none">
      <li className={liRestrictedStyle}> Гипертония 2 и 3 степени</li>
      <li className={liRestrictedStyle}>Нефрэктомия (удаленная почка)</li>
      <li className={liRestrictedStyle}>
        Серьезные проблемы со зрением (высокая степень близорукости, глаукома и
        т.п.)
      </li>
      <li className={liRestrictedStyle}>
        Любые заболевания сосудов, слабость сосудов (о слабости сосудов
        свидетельствует постоянное возникновение синяков, гематом, «лопание»
        сосудов, например, сосудов глаза, носовые кровотечения), проблемы с
        сосудами головного мозга
      </li>
      <li className={liRestrictedStyle}>Киста (локализация в яичнике)</li>
      <li className={liRestrictedStyle}>
        Мочекаменная и желчекаменная болезнь (в период обострения)
      </li>
      <li className={liRestrictedStyle}>Травмы и грыжи позвоночника</li>
    </ul>
    <div className="mt-10">
      <ConsultationSVG className="w-full" />
    </div>
  </div>
);

const footerContent = (
  <div className="flex justify-around text-gray-400 font-serif">
    <div>
      <div>ИП Павловская Алина Сергеевна</div>
      <div>ИНН 631184627974</div>
    </div>
    <div>
      <div>Email: ilovebodyflax@gmail.com</div> <div>Договор оферты</div>
    </div>
  </div>
);

const resultIconStyle =
  "text-white text-sm flex flex-col items-center gap-4 w-52 mb-10";
const textSpanStyle = "flex justify-center text-center items-center text-sm";
const resultsContent = (
  <div className="bg-rose-500 prose min-w-full p-4 gap-x-8 text-center">
    <h1 className="text-white font-serif mt-20">
      Результаты от техники Бодифлекс:
    </h1>
    <div className="flex flex-wrap justify-around items-center mt-10">
      <div className={resultIconStyle}>
        <BodySVG />
        <div>
          <span className={textSpanStyle}>
            Помогает похудеть, уменьшить объёмы тела
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <ImmuneVG />
        <div>
          <span className={textSpanStyle}>
            Укрепляет иммунную систему, улучшает самочувствие, повышает
            активность
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <CycleSVG />
        <div>
          <span className={textSpanStyle}>Ускоряет метаболизм</span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <ForkAndSpoonSVG />
        <div>
          <span className={textSpanStyle}>Уменьшает аппетит</span>
        </div>
      </div>
    </div>
    <div className="flex flex-wrap justify-around items-center">
      <div className={resultIconStyle}>
        <BackSideSVG />
        <div>
          <span className={textSpanStyle}>Избавляет от отеков и целлюлита</span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <FaceSVG />
        <div>
          <span className={textSpanStyle}>Улучшает цвет и тонус кожи</span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <StomachSVG />
        <div>
          <span className={textSpanStyle}>Нормализует работу кишечника</span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <YogaSVG />
        <div>
          <span className={textSpanStyle}>Очищает лимфатическую систему</span>
        </div>
      </div>
    </div>
  </div>
);

const Main = ({ headerImageLeft, scrollTo }: any) => {
  return (
    <>
      <div className="bg-red-100 relative lg:hidden">
        <div className="sm:flex relative h-screen clip-hero-bg-white bg-white">
          <div className="absolute flex bg-heroBgMobile bg-cover bg-center bg-no-repeat w-full h-full clip-hero-bg-mobile"></div>
        </div>
        <div className="flex justify-center items-center w-full">
          <div className="absolute flex flex-col justify-center items-center content-center w-full bottom-12">
            <div className="flex flex-wrap justify-center items-center content-center w-10/12 font-ristretto">
              <div className="text-red-500 font-semibold text-8xl leading-none">
                #ШпакМетод
              </div>
              <div className="text-cyan-600 font-semibold text-8xl leading-none">
                _Бодифлекс
              </div>
            </div>
            <div onClick={() => scrollTo("buyRef")}>
              {/* <a href="md:hidden"> */}
              <div className="bg-red-500 text-white rounded-md mt-4 p-3 pl-20 pr-20 uppercase text-xl cursor-pointer">
                Купить
              </div>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>

      <div className="lg:flex w-auto h-screen bg-rose-200 hidden lg:visible md:hidden">
        <div className="flex bg-white w-2/5 bg-cover bg-center bg-no-repeat clip-header-white relative">
          <div className="asbolute bg-alinaLay bg-cover bg-center bg-no-repeat flex bg-red-100 clip-header z-10 w-full"></div>
        </div>
        <div
          className="flex w-1/2	 flex-wrap items-center content-center justify-center mx-auto"
          ref={headerImageLeft}
        >
          <div className="flex flex-grow-0 mr-4 font-ristretto">
            <div className="text-red-500 font-semibold text-8xl leading-none">
              #ШпакМетод
            </div>
            <div className="text-cyan-600 font-semibold text-8xl leading-none">
              _Бодифлекс
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const products = [
  {
    id: 10,
    name: "Стандарт",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытому Телеграм каналу на 14 дней, в котором проходит
          тренировка. <a href="https://t.me/+Xtc9CVJ4xHcwNTIy">Перейти.</a>
        </li>
        <li className="font-bold">
          Без обратной связи и проверки домашнего задания
        </li>
        <li className="font-bold text-rose-500">
          Обращаем ваше внимание, что вам необходимо САМОСТОЯТЕЛЬНО отправить
          запрос в группу тренировки.
        </li>
        <li className="font-bold text-rose-500">
          Внимательно указывайте ваши данные, при заполнении анкеты.
        </li>
      </ul>
    ),
    price: "5 000 ₽",
    // oldPrice: "8 000 ₽"
  },
  {
    id: 11,
    name: "Стандарт плюс ",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытому Телеграм каналу 14 дней, в котором проходит
          тренировка.{" "}
          <a href="https://t.me/+Xtc9CVJ4xHcwNTIy" className="text-rose-600">
            Перейти.
          </a>
        </li>
        <li className="font-bold">
          Проверка только техники дыхания в общем чате в WhatsApp (в течении 32
          часов после начала тренировки)
        </li>
        <li className="font-bold">Жесткий решим и тотальный контроль</li>
        <li className="font-bold text-rose-500">
          Обращаем ваше внимание, что вам необходимо САМОСТОЯТЕЛЬНО отправить
          запрос в группу тренировки.
        </li>
        <li className="font-bold text-rose-500">
          Внимательно указывайте ваши данные, при заполнении анкеты.
        </li>
      </ul>
    ),
    price: "6 000 ₽",
    // oldPrice: "15 000 ₽"
  },
  {
    id: 12,
    name: "VIP",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытому Телеграм каналу на 14 дней, в котором проходит
          тренировка. <a href="https://t.me/+Xtc9CVJ4xHcwNTIy">Перейти.</a>
        </li>
        <li className="font-bold">
          Проверка всех домашних заданий в общем vip чате в WhatsApp (с 15 сентября по 22 сентября)
        </li>
        <li className="font-bold">Жесткий решим и тотальный контроль</li>
        <li className="font-bold text-rose-500">
          Обращаем ваше внимание, что вам необходимо САМОСТОЯТЕЛЬНО отправить
          запрос в группу тренировки.
        </li>
        <li className="font-bold text-rose-500">
          Внимательно указывайте ваши данные, при заполнении анкеты.
        </li>
      </ul>
    ),
    price: "7 000 ₽",
    // oldPrice: "20 000 ₽"
  },
  {
    id: 13,
    name: "VIP+",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытому Телеграм каналу на 14 дней, в котором проходит
          тренировка. <a href="https://t.me/+Xtc9CVJ4xHcwNTIy">Перейти.</a>
        </li>
        <li className="font-bold">
          ПРОВЕРКА домашних заданий лично АЛИНОЙ ШПАК в Закрытом ЧАТЕ В WhatsApp
        </li>
        <li className="font-bold">Жесткий решим и тотальный контроль</li>
        <li className="font-bold text-rose-500">
          Внимательно указывайте ваши данные, при заполнении анкеты.
        </li>
      </ul>
    ),
    price: "100 000 ₽",
  },
];

export const Bodyflex = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const introRef = useRef<HTMLDivElement>(null);
  const qaRef = useRef<HTMLDivElement>(null);
  const howRef = useRef<HTMLDivElement>(null);
  const rulesRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const contraindicationRef = useRef<HTMLDivElement>(null);
  const buyRef = useRef<HTMLDivElement>(null);

  const headerImageLeft = useRef<HTMLDivElement>(null);

  const scrollTo = (name: string) => {
    if (name === "top") window.scrollTo(0, introRef?.current?.offsetTop || 0);
    if (name === "intro") window.scrollTo(0, introRef?.current?.offsetTop || 0);
    if (name === "qa") window?.scrollTo(0, qaRef?.current?.offsetTop || 0);
    if (name === "how") window?.scrollTo(0, howRef?.current?.offsetTop || 0);
    if (name === "rules")
      window?.scrollTo(0, rulesRef?.current?.offsetTop || 0);
    if (name === "testimonials")
      window?.scrollTo(0, reviewsRef?.current?.offsetTop || 0);
    if (name === "contraindication")
      window?.scrollTo(0, contraindicationRef?.current?.offsetTop || 0);
    if (name === "buyRef") window?.scrollTo(0, buyRef?.current?.offsetTop || 0);
  };

  return (
    <div ref={topRef}>
      <Header scrollTo={scrollTo} ref={headerRef} />
      <Main headerImageLeft={headerImageLeft} scrollTo={scrollTo} />
      <div ref={introRef} />
      <TextSection content={intro} />
      {resultsContent}
      <div ref={qaRef} />
      <HeroImageContainer imageName="qa.png" />
      <ChatMessages messages={chatMessages} />
      <div ref={howRef} />
      <HeroImageContainer imageName="how-pass.png" />
      <TextSection content={howPass} />
      <div ref={rulesRef} />
      <HeroImageContainer imageName="rules.png" />
      <TextSection content={rulesContent} />
      <div ref={reviewsRef} />
      <HeroImageContainer imageName="testimonials.png" />
      <TextSection content={testimonialsText} />
      <Testimonials testimonials={testimonials} />
      <div className="bg-separator h-16 bg-repeat-x" />
      <div ref={contraindicationRef} />
      <TextSection content={contraindication} />
      <TextSection
        className="bg-gray-100 pt-4"
        content={byAgreementWithTheDoctor}
      />
      <div ref={buyRef} />
      <Products date={"15 сентября"} products={products} closed={false} />
      <Footer />
      {/* <div className="prose mx-auto p-8">{footerContent}</div> */}
    </div>
  );
};
