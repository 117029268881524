import { Home } from "pages/Home";
import { Bodyflex } from "pages/Bodyflex";
import { Oxycise } from "pages/Oxycise";
import { Pro } from "pages/Pro";
import { Routes, Route, Outlet } from "react-router-dom";
import { Charger } from "pages/Charger";
import { Oferta } from "pages/Oferta";
import { ReactComponent as BrowserError } from "../src/assets/svg/browser-error-404.svg";
import { Charity } from "pages/Charity";
import { Pay } from "pages/Pay";
import { Vip } from "pages/Vip";
import { Policy } from "pages/Oferta/Policy";

const NoMatch = ({ browserError }: any) => (
  <div className="flex items-center justify-center mt-60">
    <h1 className="w-60">
      <BrowserError />
    </h1>
  </div>
);

const Success = () => (
  <div className="flex items-center justify-center mt-60">
    <h1 className="w-60">
      Оплата прошла успешно. Спасибо за покупку!
    </h1>
  </div>
);

const Fail = () => (
  <div className="flex items-center justify-center mt-60">
    <h1 className="w-60">
      Оплата не прошла. Попробуйте еще раз.
    </h1>
  </div>
);

const App = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div>
            <Outlet />
          </div>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="bodyflex" element={<Bodyflex />} />
        <Route path="oxycise" element={<Oxycise />} />
        <Route path="pro" element={<Pro />} />
        <Route path="vip" element={<Vip />} />
        <Route path="charger" element={<Charger />} />
        <Route path="oferta" element={<Oferta />} />
        <Route path="policy" element={<Policy />} />
        <Route path="charity" element={<Charity />} />
        <Route path="pay/:productId/:price/:name" element={<Pay />} />
        <Route path="fail" element={<Fail />} />
        <Route path="success" element={<Success />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};

export default App;
