import { Oferta } from "pages/Oferta";
import { Routes, Route, Link } from "react-router-dom";

export const Footer = ({
  background = "white",
}: {
  background?: "white" | "gray";
}) => {
  const styles = {
    white: "bg-white text-gray-400",
    gray: "bg-gray-100 text-gray-800",
  };
  return (
    <div
      className={`flex text-center justify-center md:justify-around text-gray-400 font-serif ${styles[background]} p-10`}
    >
      <Routes>
        <Route path="oferta" element={<Oferta />} />
      </Routes>
      <div className="flex flex-col md:flex-row prose justify-around gap-4 w-full">
        <div>
          {/* <div>ИП Павловская Алина Сергеевна</div> */}
          <div className="prose-sm">ИП МОКШИН СЕРГЕЙ НИКОЛАЕВИЧ</div>
          {/* <div>ИНН 631102456008</div> */}
          <div>ИНН 631102456008</div>
        </div>
        <div>
          <Link to="/policy"><div className="prose-sm">Политика конфиденциальности персональных данных</div></Link>
          <Link to="/oferta">
            <div className="prose-xs">Договор оферты</div>
          </Link>
        </div>
      </div>
    </div>
  );
};
