import { Products } from "components/Products";
import { useParams } from "react-router-dom";

//Шпакметод Зарядка 

// Шпакметод Зарядка 

// - продление к базовому курсу до 1 января 2025 года 
// - проверка упражнений в чате wast’app с 3 по 11 января 
// - после оплаты добавится в группу 
// https://t.me/+Dc3U9X-nHiQzYmMy

const productsDB = [{
  id: 37,
  name: "Шпакметод Зарядка",
  description: (
    <div className="flex flex-row justify-around prose">
    <ul>
      <div>• Продление доступа к базовому курсу до 1 января 2025 года</div>
      <div>• Проверка упражнений в чате wast’app с 3 по 13 января</div>
      <div>• После оплаты добавится в группу</div>
      <div>
        <a href="https://t.me/+Dc3U9X-nHiQzYmMy">https://t.me/+Dc3U9X-nHiQzYmMy</a>
      </div>
    </ul></div>
  ),
  price: 3000,
}];


export const Pay = () => {
  const { productId, price, name } = useParams();

  const isInDB = productsDB.find((product) => Number(product.id) === Number(productId));

  const products = isInDB ? [isInDB] : [{
    id: productId,
    name: name,
    description: <></>,
    price,
  }]

  console.log(products)

  if ((!productId || !price || !name)) return <div>Нет товара</div>


  return (
    <div className="prose mx-auto text-center mt-10">
      <h1>{name}</h1>
      <Products products={products} openModal={isInDB ? false : true} />
    </div>
  );
};
