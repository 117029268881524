import { Modal } from "components/Modal";

// const alina = () => {
//   return (
//     <div>
//       ИП Павловская Алина Сергеевна <br />
//       Адрес: 443110, Самарская область, г.Самара, ул.Ново - Садовая, д. 19, кв.
//       86 <br />
//       ОГРНИП 318631300101270 <br />
//       ИНН 631184627974 <br />
//       Расчетный счет 40802810700000662550 <br />
//       Банк АО «Тинькофф Банк» <br />
//       Юридический адрес банка: Москва, 123060, 1 - й Волоколамский проезд, д.
//       10, стр. 1 <br />
//       Корр.счет банка 30101810145250000974 <br />
//       ИНН банка 7710140679 <br />
//       БИК банка 044525974 <br />
//       Электронный адрес: ilovebodyflex@gmail.com
//     </div>
//   );
// };

const mokshin = () => {
  return (
    <div>
      ИП Мокшин Сергей Николаевич <br />
      Адрес: 443045 г Самара пр-кт Кирова 225
      <br />
      Огрнип 320631300124081
      <br />
      ИНН 631102456008
      <br />
      Расчётный счёт 40802810712250001496
      <br />
      Филиал центральный банка втб
      <br />
      Г Москва Ленинградский проспект 77 корп 2<br />
      Кор счёт 30101810145250000411
      <br />
      ИНН банка 631102456008
      <br />
      БИК банка 044525411
      <br />
      Электронный адрес: ilovebodyflex @gmail.com
    </div>
  );
};

export const getLegalDetails = () => mokshin();

export const getLegalName = () => "ИП МОКШИН СЕРГЕЙ НИКОЛАЕВИЧ ИНН 631102456008";

// export const getLegalName = () => {
//   return !window.location.href.includes("ilovebodyflex.online")
//     ? "ИП ПАВЛОВСКАЯ АЛИНА СЕРГЕЕВНА ИНН 631184627974"
//     : "ИП МОКШИН СЕРГЕЙ НИКОЛАЕВИЧ ИНН 631102456008";
// };


export const Oferta = () => {
  return (
    <Modal isModalOpen={true} fixed={false}>
      <div className="oferta prose">
        <h1>ПУБЛИЧНАЯ ОФЕРТА (Пользовательское соглашение)</h1>
        <h2>Договор-оферта на оказание информационно-консультационных услуг</h2>
        <p>Настоящая Оферта является предложением ИП Мокшин Сергей Николаевич, адрес: 443045 г Самара пр-кт Кирова 225, ИНН 631102456008, ОГРНИП 320631300124081, далее именуемого «Сервис», заключения с Вами, дееспособным физическим лицом, либо юридическим лицом, далее именуемым «Пользователь», данной публичной оферты (Пользовательского соглашения). Поступление денежных средств в полном размере за оплаченный Пользователем заказ Инфоматериала, Курса, осуществленный им на Интернет-сайтах http://ilovebodyflex.online/charger, http://ilovebodyflex.ru/bodyflex, http://ilovebodyflex.ru/oxycise, http://ilovebodyflex.ru/pro,  http://ilovebodyflex.ru/ либо начало использования Инфоматериалов, находящихся в свободном доступе (распространяемых бесплатно), является полным и безоговорочным согласием (акцептом) Пользователя на заключение Пользовательского соглашения (далее – «Соглашение») на следующих условиях:</p>
        <h2>1. Термины и определения</h2>
        <p>
          Сайт – Интернет-сайт, принадлежащий Сервису и размещенный в сети Интернет по адресу http://ilovebodyflex.online/charger, http://ilovebodyflex.ru/bodyflex, http://ilovebodyflex.ru/oxycise, http://ilovebodyflex.ru/pro, http://ilovebodyflex.ru/ включая все его разделы, страницы, файловую структуру и любые иные структурные элементы, контент, Инфоматериалы, исходный и объектный код, произведения дизайна, графики, аудиовизуальные произведения, фотографии и иные объекты авторского права.
        </p>
        <p>
          «Инфоматериал» – информационно-обучающий аудио-, видео- или текстовый материал, размещенный (транслируемый) Сервисом по ссылке, предоставляемой Пользователю.
        </p>
        <p>
          «Услуги доступа» – услуги по предоставлению доступа к Инфоматериалам путем организации его воспроизведения и просмотра Пользователем без возможности сохранения на компьютер (или иное устройство) Пользователя или с такой возможностью.

        </p>
        <p> «Информационные услуги» – услуги Сервиса по предоставлению доступа к Курсу.
        </p>
        <p>«Услуги» – Услуги доступа, Информационные услуги
        </p>
        <p>«Курс» – информационный комплекс, включающий в себя различные Инфоматериалы, размещенные по ссылке (может проводиться с участием Тренера), предоставляемой Пользователю.</p>
        <p>«Тренер» – определяемый Сервисом специалист, который ведет Курс</p>
        <p>«Объявление» – информация об Инфоматериале, размещаемая на Сайте на одной или нескольких веб-страницах.</p>
        <h2>2. Предмет Соглашения</h2>
        <p>
          2.1. Сервис после полного поступления оплаты от Пользователя, в пределах и на условиях, указанных в Объявлении, обязуется: - оказать Пользователю Услуги доступа к Инфоматериалам и (или) Информационные услуги. Доступ к инфоматериалам предоставляются Пользователю на 14 календарных дней с момента открытия Доступа к информатериалам.
        </p>
        <p>2.2. В соответствующем Объявлении определяется существенные условия и параметры, в том числе: - для Курса – краткое содержание (программа), время и условия его проведения; наличие и набор Информационных услуг; стоимость Услуг;
          - для Инфоматериала – краткое описание или содержание; стоимость Услуги доступа;
        </p>
        <h2>3.	ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
        <h2>3.1.  «Сервис» вправе:</h2>
        <p>3.1.1. Изменять правила пользования «Сайтом», «Услугами», а также изменять содержание «Сайта», «Услуг». Изменения вступают в силу с момента публикации новой редакции «Соглашения» на «Сайте».
        </p>
        <p>3.1.2. Ограничить доступ к «Сайту» или «Услугам» в случае нарушения «Пользователем» условий настоящего «Соглашения».
        </p>
        <p>3.1.3. Изменять размер оплаты, взимаемый за Услуги или «Услуги Доступа».</p>
        <h2>3.2. «Пользователь» вправе:</h2>
        <p>3.2.1. Пользоваться «Сайтом», «Услугами», «Услугами доступа» исключительно в целях и порядке, предусмотренных «Соглашением» и не запрещенных законодательством Российской Федерации.</p>
        <h3>3.3. «Пользователь» обязуется:</h3>
        <p>3.3.1. Предоставлять по запросу «Сервиса» дополнительную информацию, которая имеет непосредственное отношение к предоставляемым «Услугам».</p>
        <p>3.3.2. Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании «Сайта», «Услуг».</p>
        <p>3.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу «Сайта».</p>
        <p>3.3.4. Не распространять с использованием «Сайта» любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах.</p>
        <p>3.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.</p>
        <p>3.3.6. Не использовать «Сайт» для распространения информации рекламного характера, иначе как с согласия «Правообладателя».</p>
        <p>3.3.7. Не использовать «Сайт» с целью:
          - загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти.
          - побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.
          - нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.
          - представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав.</p>
        <h3>3.4. «Пользователю» запрещается:</h3>
        <p>Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания «Сайта»;</p>
        <p>Нарушать надлежащее функционирование «Сайта»;</p>
        <p>Несанкционированный доступ к функциям «Сайта», любым другим системам или сетям, относящимся к данному «Сайту», а также к любым услугам, предлагаемым на «Сайте»;</p>
        <p>Нарушать систему безопасности или аутентификации на «Сайте» или в любой сети, относящейся к «Сайту»</p>
        <p>Использовать «Сайт» и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права интернет-ресурса или других лиц.</p>
        <h3>4. Оказание Услуг доступа</h3>
        <p>4.1. С момента полной оплаты Услуг доступа Пользователь получает гиперссылку, которая ведет к закрытому от общего доступа разделу (группе в социальной сети), где Пользователю открыт персональный доступ к оплаченным Инфоматериалам или Курсу в течение срока оказания Услуг доступа.</p>
        <p>4.2. Пользователь самостоятельно обеспечивает установку, настройку и использование технических средств и программного обеспечения, необходимых для получения Услуг доступа, а также для выполнения заданий (если таковые предусмотрены).</p>
        <p>4.3. Сервис вправе размещать в свободный (бесплатный) доступ Инфоматериалы или Курсы и по собственному усмотрению прекращать к ним свободный (бесплатный) доступ.</p>
        <h3>5. Оказание Информационных услуг (участие Пользователя в Курсе)</h3>
        <p>5.1. С момента полной оплаты Информационных услуг, Пользователь имеет право принять участие в Курсе, условия проведения которого указаны в соответствующем Объявлении.</p>
        <p>5.2. Сервис вправе в одностороннем порядке изменить время проведения Курса, в том числе промежуточные сроки в рамках Курса, уведомив Пользователя не менее чем за 3 (три) рабочих дня.</p>
        <p>5.3. Сервис вправе в одностороннем порядке заменять Тренера.</p>
        <p>5.4. Пользователь самостоятельно несет риск участия в Курсе. В случае, если Пользователь не принял участие в Курсе либо в его промежуточных этапах, возврат стоимости Услуг либо предоставление права участия в ином Курсе Сервисом не производится, либо производится по усмотрению Сервиса.</p>
        <p>5.5. На протяжении Курса Пользователь обязуется выполнять все инструкции и указания Сервиса (Тренера), соблюдать дисциплину и правила, установленные Сервисом для проведения Курса, не препятствовать Сервису (Тренеру) в проведении Курса. При наличии таких нарушений Сервис (Тренер) вправе отстранить Пользователя от участия в Курсе либо прекратить участие Пользователя в Курсе.</p>
        <p>5.6. Информационные услуги считаются оказанными с окончанием последнего модуля, элемента Курса, которые пройдены Пользователем в рамках Курса.</p>
        <h3>6. Порядок оплаты</h3>
        <p>6.1. Пользователь обязуется уплатить Сервису стоимость Услуг, указанную в Объявлении, на условиях полной предварительной оплаты.</p>
        <p>6.2. Порядок перечисления денежных средств, а также иные условия оплаты определяются на Сайте, а также обусловлены правилами сторонних платежных систем.</p>
        <p>6.3. Стоимость Курса, Инфоматериала, Услуг доступа может быть изменена Сервисом в одностороннем порядке.</p>
        <p>6.4. Курс, Инфоматериал, услуги доступа считаются оплаченными Пользователем с момента получения Сервисом информации от платежной системы, используемой для их оплаты, об успешном проведении транзакции в пользу Сервиса в полном объеме.</p>
        <p>6.5. Все расходы по переводу денежных средств, включая комиссии банков, платежных систем, платежных агрегаторов, несет Пользователь.</p>
        <p>6.6. Пользователь обязуется хранить все документы, подтверждающие осуществление оплаты, до момента зачисления денежных средств на баланс Сервиса.</p>
        <h3>7. Возвраты</h3>
        <p>7.1. Пользователь использует содержащееся в Инфоматериалах, Курсах информацию по своему усмотрению и на свой риск. Сервис не гарантирует достоверность, актуальность, практическую применимость и ценность информации, содержащейся в Инфоматериалах, Курсах.</p>
        <p>7.2. Порядок возврата денежных средств и расторжения договора определяется действующим законодательством Российской Федерации.</p>
        <h3>8. Права на объекты интеллектуальной собственности</h3>
        <p>8.1. Сервису принадлежат права использования или исключительные права на объекты интеллектуальной собственности, используемые в Инфоматериалах, Курсах. Пользователю предоставляется право использования таких объектов интеллектуальной собственности исключительно в пределах, необходимых для получения доступа к Инфоматериалам или Курсам или для получения Информационных услуг в ходе Курса. Если Сервисом предоставлена возможность копирования (сохранения) Инфоматериала, Пользователь вправе воспроизводить его на технических средствах Пользователя.</p>
        <p>8.2. Распространение (безвозмездное или за плату), распечатывание, репродуцирование, переработка и изменение Инфоматериалов или информации, полученной в ходе Курсов, осуществление любых иных действий с Инфоматериалом или его частью не допускаются.</p>
        <p>8.3. За нарушение п.8.2. Сервис вправе взыскать с Пользователя штраф в размере сто тысяч рублей за каждый случай.</p>
        <h3>9. Конфиденциальность и защита персональных данных</h3>
        <p>9.1. Все сведения, истребуемые Сервисом от Пользователя используются исключительно с целью оказания Пользователю Услуг, а также заключения и исполнения Соглашения.</p>
        <p>9.2. Пользователь выражает свое согласие Сервису на обработку своих персональных данных, а также любой иной информации, предоставленной Пользователем, как с использованием средств автоматизации, так и без использования таких средств, в целях, указанных в пункте 9.1 Соглашения, а равно в целях маркетинговых исследований и продвижения продуктов Сервиса, с даты настоящего согласия до даты, наступающей через 5 (пять) лет с даты прекращения обязательств Сторон по Соглашению, с возможностью отзыва такого согласия на основании письменного заявления Пользователя.</p>
        <p>9.3. Пользователь предоставляет свое согласие Сервису на направление Пользователю периодических уведомлений, сообщений, новостных рассылок и других информационных и рекламных материалов на адрес электронной почты, номер телефона или с использованием иной контактной информации и средств коммуникации, предоставленных Пользователем Сервису.</p>
        <h3>10. Ответственность. Порядок разрешения споров</h3>
        <p>10.1. Сервис не несет ответственности за любые убытки, возникшие в результате использования Пользователем в своей практической деятельности информации, содержащейся на Сайте, в Инфоматериалах, равно как полученной в ходе участия Пользователя в Курсе.</p>
        <p>10.2. Стороны установили претензионный досудебный порядок урегулирования разногласий и споров. Претензия должна быть направлена в письменной форме заказным письмом с уведомлением. Срок для ответа на предъявленную претензию - 30 (тридцать) календарных дней с момента получения претензии другой Стороной.</p>
        <p>10.3. В случае неполучения ответа на претензию в указанный в пункте 9.2 Соглашения срок либо недостижения Сторонами взаимоприемлемого решения, спор передается на разрешение суда в соответствии с правилами подведомственности, определенными законодательством Российской Федерации, по месту нахождения Сервиса.</p>
        <p>10.4. Сервис не несет ответственности за ненадлежащее оказание Услуг, если оно явилось следствием недостоверности, недостаточности или несвоевременности предоставленных Заказчиком сведений, а также вследствие иных нарушений Оферты со стороны Заказчика. Сервис не несет ответственности за несоответствие оказанных Услуг ожиданиям Заказчика. При этом субъективная отрицательная оценка Заказчиком оказанных Сервисом Услуг не является основанием считать Услуги неоказанными или оказанными ненадлежащим образом.</p>
        <h3>11. Изменение и расторжение Соглашения</h3>
        <p>11.1. Сервис имеет право в любое время вносить изменения и (или) дополнения в Соглашение. Сервис уведомляет Пользователя об этом, размещая соответствующую информацию одним или несколькими способами (по выбору Сервиса) путем: - размещения соответствующего уведомления на Сайте; - направления сообщения на адрес электронной почты или номер телефона Пользователя</p>
        <p>11.2. Любые изменения и (или) дополнения Соглашения применяются Сервисом с момента вступления их в силу, определяемому Сервисом, и распространяются на всех Пользователей, в том числе на заключивших Соглашение ранее даты вступления в силу таких изменений и (или) дополнений.</p>
        <p>11.3. Сервис имеет право в одностороннем порядке отказаться от Соглашения: - в любой момент при исполнении всех своих существующих обязательств перед Пользователем; - в случаях, указанных в пунктах 5.4, 5.5 Соглашения; - в случае нарушения Пользователем условий Соглашения или действующего законодательства. В случае одностороннего отказа Сервиса, раздел 7 Соглашения о возвратах не применяется, а Услуги по Соглашению считаются оказанными Сервисом в полном объеме.</p>
        <h3>2. Прочие условия</h3>
        <p>12.1. Во всем, что не урегулировано Соглашением Стороны будут руководствоваться законодательством Российской Федерации.</p>
        <p>12.2. Все уведомления, указания, извещения, согласия, документы и иного рода сообщения в связи с ходом выполнения Соглашения должны исходить от Пользователя лично либо от уполномоченных лиц Пользователя или Сервиса и могут быть направлены с помощью средств телефонной связи, электронной почты или иных электронных средств коммуникации, позволяющих идентифицировать отправителя, получателя, время отправления и получения, а также сохранять и подтверждать историю обмена корреспонденцией, если иное прямо не предусмотрено Соглашением или законодательством.</p>
        <p>12.3. Права и обязанности по Соглашению не могут быть переданы Пользователем третьему лицу без предварительного согласия Сервиса.</p>
        <p>12.4. В случае, если настоящее Соглашение заключается от имени юридического лица, то при акцепте Оферты Пользователь подтверждает и гарантирует, что акцепт осуществлен надлежаще уполномоченным представителем Пользователя.</p>
        <h3>13. Реквизиты Сервиса</h3>
        <p>ИП Мокшин Сергей Николаевич <br/>Адрес: 443045 г Самара пр-кт Кирова 225<br/>Огрнип 320631300124081<br/>ИНН 631102456008<br/>Расчётный счёт 40802810712250001496<br/>Филиал центральный банка втб<br/>Г Москва Ленинградский проспект 77 корп 2<br/>Кор счёт 30101810145250000411<br/>ИНН банка 631102456008<br/>БИК банка 044525411<br/>Электронный адрес: shpakmethod@yandex.ru</p>










        <ul>
          <li>— предоставление обучающих материалов в электронном виде;</li>
          <li>— проведение тематических интернет-семинаров (вебинаров);</li>
          <li>
            — групповое и индивидуальное обучение (коучинг), в том числе с
            использованием телекоммуникационных средств;
          </li>
          <li>— прочие услуги.</li>
        </ul>
        <p>Заказчик обязуется оплатить эти Услуги.</p>
        <p>
          2.2. Под термином «вебинар» подразумевается проведение
          веб-консультаций, онлайн-занятий и презентаций посредством Интернет в
          режиме реального времени. При проведении вебинара каждый участник
          находится в режиме «онлайн» перед своим компьютером, поддерживая связь
          с остальными участниками при помощи веб-приложения.
        </p>
        <p>3.1. Исполнитель обязан: </p>
        <p>
          3.1.1. Оказывать Заказчику услуги или предоставлять доступ к
          продуктам, на которые Заказчик оформил заказ, в порядке, указанном на
          сайте{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>{" "}
          на странице с описанием заказываемой услуги или продукта и положениями
          настоящего Договора.
        </p>
        <p>
          3.1.2. Обеспечить Заказчика всеми заявленными на странице с описанием
          заказываемой услуги или продукта функциональными возможностями.
        </p>
        <p>
          3.1.3. Предоставлять консультации по вопросам, которые появляются в
          ходе использования продуктов или услуг.
        </p>
        <p>
          3.1.4. При невозможности предоставления Услуг предусмотренным этим
          Договором, немедленно уведомить об этом Заказчика письменно или по
          телефону.
        </p>
        <p>
          3.1.5. Информировать Заказчиков об изменениях (дополнениях) в условиях
          настоящего Договора, публикуя новую редакцию в сети Интернет по
          адресу:{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
        </p>
        <p>3.2. Исполнитель имеет право: </p>
        <p>
          3.2.1. Приостановить предоставление Услуг при нарушении Заказчиком
          настоящего договора.
        </p>
        <p>
          3.2.2. Не предоставлять Услуги без предварительной оплаты за них на
          странице с описанием заказываемой услуги или продукта.
        </p>
        <p>
          3.2.3. Заблокировать аккаунт Заказчика и расторгнуть Договор в
          одностороннем внесудебном порядке в случае оскорбительного,
          неэтичного, агрессивного поведения заказчика, которое выражается в
          словах (электронные письма, комментарии или любое другое цифровое
          сообщение, а также устная речь в разговоре по телефону, через Skype
          или при личной встрече) или поступках (нарушение положений настоящего
          Договора, причинение вреда Ресурсу{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          , его цифровым обучающим продуктам и любой другой информации, которая
          размещается на{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          , в том числе причинение вреда учетным записям Заказчиков).
        </p>
        <p>
          3.2.4 В целях постоянного улучшения качества предоставляемых услуг и
          цифровых обучающих продуктов по собственной инициативе изменять или
          модифицировать услугу или продукт (производить действия, направленные
          на исправление существующих ошибок, неточностей, улучшение внешнего
          вида, изменение функциональных характеристик Ресурса{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          , программного обеспечения и т. д.) в любое время без предварительного
          уведомления.
        </p>
        <p>
          3.2.5 Исполнитель не дает никаких гарантий в отношении результатов
          обучения при использовании методик и технологий, описанных на сайте{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          , а также в обучающих материалах. Исполнитель также не дает никаких
          гарантий в отношении результатов обучения при использовании цифровых
          обучающих продуктов сайта{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          .
        </p>
        <p>3.3. Заказчик обязан:</p>
        <p>3.3.1. Соблюдать правила настоящего Договора.</p>
        <p>
          3.3.2. Предоставлять достоверную Информацию о себе в процессе создания
          учетной записи (аккаунта).
        </p>
        <p>
          3.3.3. Не воспроизводить, не повторять и не копировать, не продавать и
          не перепродавать, а также не использовать для каких-либо коммерческих
          целей какие-либо части Ресурса{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          , кроме тех случаев, когда такое разрешение письменно дано Заказчику
          Исполнителем{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          . Это же относится и к цифровым обучающим продуктам, как платным, так
          и бесплатным.
        </p>
        <p>
          3.3.4. Не передавать третьим лицам свой логин/пароль, предоставленный
          Исполнителем для доступа к платным продуктам или услугам. При
          нарушении данного пункта настоящего договора Исполнитель вправе
          заблокировать или удалить аккаунт Заказчика, который нарушил Договор,
          а также аккаунты Заказчиков, которым он передал доступ к своему
          аккаунту.
        </p>
        <p>
          3.3.5. Оплатить за Услуги в размере, определенном данным Договором и
          на странице с описанием заказываемой услуги или продукта.
        </p>
        <p>
          3.3.6 Самостоятельно следить за окончанием срока действия услуги или
          доступа к продукту.
        </p>
        <p>
          3.3.7 В случае, если Заказчику станет известно о любом
          несанкционированном использовании его пароля, логина или реквизитов
          личного аккаунта, Заказчик обязуется незамедлительно уведомить об этом
          Исполнителя, отправив соответствующее электронное сообщение по адресу:
          ilovebodyflex@gmail.com
        </p>
        <p>3.4. Заказчик имеет право:</p>
        <p>
          3.4.2. Прекратить пользоваться Ресурсом{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>{" "}
          в любой момент. Заказчик вправе в любой момент удалить свою учетную
          запись, подав об этом письменный запрос Исполнителю.
        </p>
        <p>
          3.4.3. Заказчик не имеет права требовать замену одного цифрового
          обучающего продукта на другой после того, как он произвел оплату.
        </p>
        <h2>4. ПОРЯДОК РАСЧЕТОВ </h2>
        <p>
          4.1. Стоимость Услуг по настоящему Договору указывается на сайте{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>{" "}
          на странице с описанием заказываемой услуги или продукта.
        </p>
        <p>
          4.2. Оплата осуществляется Заказчиком на основании счета,
          выставленного Исполнителем или теми способами, которые указаны на
          странице с описанием заказываемой услуги или продукта авансовым
          платежом в размере 100%.
        </p>
        <p>
          4.3. Датой оплаты считается дата поступления денежных средств на
          расчётный счёт Исполнителя.
        </p>
        <p>
          4.4. Если Заказчик оплачивает часть стоимости услуг или продукта и не
          вносит своевременно следующий платеж, Исполнитель имеет право
          прекратить доступ к услуге или продукту.
        </p>
        <h2>5. УСЛОВИЯ И ПОРЯДОК ВОЗВРАТА ОПЛАТЫ ИСПОЛНИТЕЛЕМ ЗАКАЗЧИКУ</h2>
        <p>
          5.1. Заказчик вправе подать претензию и затребовать возврат оплаты за
          приобретенный им доступ к цифровому обучающему продукту не позднее чем
          через 15 дней с момента покупки.
        </p>
        <p>
          5.2. Заказчик должен отправить официальное письмо, подкрепленное
          личной подписью, на электронную почту Исполнителя с подробным
          обоснованием своей претензии и информацией о своей покупке (дата,
          идентификатор заказа, сумма заказа, способ оплаты, электронный адрес,
          на который был оформлен заказ продукта). После рассмотрения претензии
          Исполнителем (не больше 7 рабочих дней) Заказчик получит письмо с
          дальнейшей информацией и решением по его претензии.
        </p>
        <p>
          5.3. Исполнитель обязуется рассмотреть претензию Заказчика в случае,
          если он действует в соответствии с пп. 5.1, 5.2.
        </p>
        <p>
          5.4. В случае, если Исполнитель сочтет обоснование претензии
          Заказчиком исчерпывающим и попадающим под условия Гарантий, описанных
          на сайте{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>{" "}
          по адресу:{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          /oferta, Исполнитель обязуется выплатить Заказчику полную сумму, за
          которую он приобрел доступ к данному цифровому обучающему продукту. В
          случае, если претензия Заказчика не обоснована или не попадает под
          условия Гарантий, а также противоречит данному Договору, Исполнитель
          вправе отказать Заказчику в компенсации.
        </p>
        <p>
          5.5. Заказчик не имеет права подавать претензию и просить компенсацию
          в случае, если по каким-то причинам, не зависящим от Исполнителя, он
          не воспользовался или не смог воспользоваться своим аккаунтом и не
          пользовался продуктом, включая нарушение работы линий связи,
          неисправность оборудования, невыполнение обязательств поставщиков тех
          или иных услуг и т.п.
        </p>
        <p>
          5.6. Исполнитель не возвращает оплату за заказанные Заказчиком услуги.
        </p>
        <h2>6. КОНФИДЕНЦИАЛЬНОСТЬ </h2>
        <p>
          6.1. Стороны берут на себя обязательства по соблюдению
          конфиденциальности полученной документации и информации в рамках
          настоящего Договора. Стороны предпримут все необходимые меры для того,
          чтобы предотвратить полное или частичное разглашение указанных
          сведений или ознакомление с ними третьих лиц без предварительной
          взаимной договоренности. С переданной документацией и информацией
          могут быть ознакомлены только те лица из персонала Сторон или
          уполномоченные ими лица, которые непосредственно связаны с выполнением
          обязательств по настоящему Договору.
        </p>
        <p>
          6.2. В случае нарушения Сторонами условий конфиденциальности, в
          результате чего одной из Сторон был причинен имущественный и/или ущерб
          деловой репутации, виновная Сторона обязуется возместить документально
          подтвержденный причиненный ущерб.
        </p>
        <p>
          6.3. Размещая персональные и иные данные и сведения на Ресурсе{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          , Заказчик дает согласие на то, что Исполнитель (и/или его
          уполномоченные представители) будут получать, собирать,
          систематизировать, накапливать, хранить, уточнять (обновлять,
          изменять), использовать и иным образом обрабатывать (в том числе в
          электронном виде) персональные данные Заказчика в целях исполнения
          Договора.
        </p>
        <p>
          6.4. Заказчик соглашается на передачу третьим лицам персональных и
          иных данных, в том числе для целей их обработки, для обеспечения
          функционирования ресурса{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          , реализации партнерских и иных программ.
        </p>
        <p>
          6.5. Цель обработки персональных данных Заказчика заключается в
          оказании последнему услуг и предоставлении возможности использования
          ресурсов{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>{" "}
          , проведении рекламных кампаний, предоставлении таргетированной
          рекламы, проведении статистических исследований и анализа полученных
          статистических данных, а также осуществлении иных действий, описанных
          в Договоре.
        </p>
        <p>
          6.6. Обработка персональных данных Заказчика осуществляется в срок с
          момента регистрации Заказчика и до момента удаления его учетной
          записи. Удаление учетной записи производится по письменному заявлению
          Заказчика и автоматически влечет удаление аккаунта Заказчика.
        </p>
        <p>
          6.7. Заказчик дает согласие на то, что Исполнитель будет направлять в
          адрес Заказчика корреспонденцию на указанный им адрес электронной
          почты, информацию о проводимых Исполнителем и его партнерами рекламных
          акциях и иную информацию.
        </p>
        <p>
          6.8. В состав конфиденциальной информации не входит информация,
          размещаемая Заказчиком на сайте Ресурса и доступная для широкого круга
          лиц.
        </p>
        <h2>7. ОТВЕТСТВЕННОСТЬ СТОРОН ЗА НАРУШЕНИЕ ДОГОВОРА</h2>
        <p>
          7.1. В случае нарушения своих обязательств по данному Договору Стороны
          несут ответственность, предусмотренную настоящим Договором и
          действующим законодательством РФ. Нарушением обязательства является
          его исполнение или ненадлежащее исполнение, то есть исполнение с
          нарушениями условий, определенных содержанием обязательства.
        </p>
        <p>
          7.2. Стороны не несут ответственности за нарушение своих обязательств
          по этому Договору, если оно произошло не по их вине. Сторона считается
          невиновной, если докажет, что ею были предприняты все зависящие от нее
          меры по надлежащему исполнению обязательства.
        </p>
        <p>
          7.3. За нарушение п. 3.4.2 данного Договора, Заказчик несет
          ответственность в соответствии с действующим законодательством
          Российской Федерации
        </p>
        <p>
          7.4 Исполнитель гарантирует, что владеет веб-сайтом{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>{" "}
          на законных основаниях, вправе использовать его в соответствии с
          условиями настоящего Договора и обладает всеми необходимыми правами на
          размещение информационных материалов на указанном интернет-сайте
          (Ресурсе).
        </p>
        <p>
          7.5. Заказчик несет полную ответственность за соблюдение требований
          законодательства РФ, в том числе законодательства о рекламе, о защите
          авторских и смежных прав, об охране товарных знаков и знаков
          обслуживания, но не ограничиваясь перечисленным, включая полную
          ответственность за содержание и форму материалов, которые он размещает
          на Ресурсе.
        </p>
        <p>
          7.6. Заказчик соглашается, что информация, предоставленная ему как
          часть Ресурса{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          , может являться объектом интеллектуальной собственности, права на
          который защищены и принадлежат другим Заказчикам, партнерам или
          рекламодателям, которые размещают такую информацию на{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          . Заказчик не вправе вносить изменения, передавать в аренду,
          передавать на условиях займа, продавать, распространять или создавать
          производные работы на основе такого Содержания (полностью или в
          части), за исключением случаев, когда такие действия были письменно
          прямо разрешены собственниками такого Содержания в соответствии с
          условиями отдельного соглашения.
        </p>
        <p>
          7.7. В отношении текстовых материалов (статей, публикаций, находящихся
          в свободном публичном доступе на Ресурсе{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          ) допускается их распространение при условии, что будет дана ссылка на
          страницу с данным текстовым материалом и указано ФИО автора,
          написавшего данный текстовый материал.
        </p>
        <p>
          7.8. Заказчик соглашается с тем, что Исполнитель предоставляет доступ
          к услугам или цифровым продуктам как есть, и не вправе требовать
          дополнительной доработки оплаченной им услуги или продукта, а также
          его отдельных компонентов, информационных материалов, а также
          изменения правил предоставления услуг или доступа к продуктам,
          описанных положениями настоящего Договора.
        </p>
        <p>
          7.9. Заказчик соглашается с тем, что при несоблюдении им положений
          настоящего Договора его аккаунт на сайте{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>{" "}
          может быть заблокирован или удален по решению Исполнителя.
        </p>
        <p>
          7.10. Исполнитель не несет ответственности за любые прямые или
          косвенные убытки, произошедшие из-за: использования либо невозможности
          использования сайта либо отдельных сервисов; несанкционированного
          доступа к коммуникациям Заказчика; заявления или поведения любого
          третьего лица на сайте.
        </p>
        <p>
          7.11. Исполнитель не несет ответственность за какую-либо информацию,
          размещенную Заказчиком на ресурсе{" "}
          <a href={`${window.location.hostname}`}>{window.location.hostname}</a>
          , включая, но не ограничиваясь, информацию, защищенную авторским
          правом, без прямого согласия владельца авторского права.
        </p>
        <h2>8. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</h2>
        <p>
          8.1. Стороны освобождаются от ответственности за частичное или полное
          неисполнение обязательств по настоящему Договору, вызванное
          обстоятельствами непреодолимой силы, возникшими после его заключения.
          К таким обстоятельствам, в частности, Стороны относят: стихийные
          бедствия; природные и промышленные катастрофы; террористические акты;
          военные действия; гражданские беспорядки; принятие органами
          государственной власти или органами местного самоуправления актов,
          содержащих запреты или ограничения в отношении деятельности Сторон по
          настоящему Договору; иные обстоятельства, которые не могут быть
          заранее предвидены или предотвращены Сторонами и делают невозможным
          исполнение обязательств Сторон по Договору.
        </p>
        <p>
          8.2. Сторона, желающая быть освобожденной от ответственности в силу
          наступления обстоятельств непреодолимой силы, незамедлительно, не
          позднее 3 (Трех) рабочих дней извещает о таких обстоятельствах другую
          Сторону. Несвоевременное уведомление о наступлении обстоятельств
          непреодолимой силы лишает Сторону права на освобождение от
          ответственности.
        </p>
        <p>
          8.3. При наступлении обстоятельств непреодолимой силы, препятствующих
          исполнению обязательств по настоящему Договору, срок выполнения
          Сторонами таких обязательств переносится соразмерно времени действия
          таких обстоятельств, а также времени, требуемого для устранения их
          последствий, но не более чем на 60 (Шестьдесят) календарных дней. В
          случае, если обстоятельства непреодолимой силы продолжают действовать
          более указанного срока либо когда при их наступлении обеим Сторонам
          становится очевидным, что они будут действовать более этого срока,
          Стороны обязуются обсудить возможности альтернативных способов
          исполнения настоящего Договора или его прекращения без возмещения
          убытков.
        </p>
        <h2>9. РЕШЕНИЕ СПОРНЫХ ВОПРОСОВ </h2>
        <p>
          9.1. Споры и/или разногласия, связанные с настоящим Договором, его
          заключением, либо возникающие в процессе исполнения условий настоящего
          Договора, разрешаются путем переговоров между представителями Сторон.
        </p>
        <p>
          9.2. Все претензии Сторон должны быть оформлены в письменном виде и
          подписаны уполномоченными лицами.
        </p>
        <p>
          9.3. Сторона, получившая претензию, обязана предоставить другой
          Стороне мотивированный ответ в течение 10 (Десяти) рабочих дней со дня
          получения претензии
        </p>
        <p>
          9.4. Если спор невозможно урегулировать путем переговоров, то он
          разрешается в судебном порядке согласно установленной
          подведомственности и подсудности такого спора в порядке, определенном
          соответствующим действующим законодательством РФ.
        </p>
        <h2>10. СРОК ДЕЙСТВИЯ ДОГОВОРА И ПОРЯДОК ЕГО РАСТОРЖЕНИЯ </h2>
        <p>
          10.1. Настоящий Договор-оферта вступает в силу с момента его акцепта
          Заказчиком и действует до полного исполнения Сторонами взятых на себя
          обязательств. Датой акцепта является дата оплаты (согласно п. 4.3.
          настоящего Договора) Услуг Заказчиком.
        </p>
        <p>
          10.2. Окончание срока настоящего Договора не освобождает Стороны от
          ответственности за его нарушение, которое имело место во время
          действия настоящего Договора.
        </p>
        <p>
          10.3. Настоящий Договор может быть расторгнут досрочно по обоюдному
          соглашению Сторон.
        </p>
        <h2>11. ПРОЧИЕ УСЛОВИЯ </h2>
        <p>
          11.1. По всем вопросам Заказчики могут обращаться по адресу:
          ilovebodyflex@gmail.com
        </p>
        <p>
          11.2. Договор – оферта не требует подписания и скрепления печатями
          Сторон, сохраняя при этом полную юридическую силу.
        </p>
        <p>11.3. Осуществляя акцепт настоящей Оферты Заказчик:</p>
        <ul>
          <li>
            - дает согласие на обработку и использование Исполнителем его
            персональных данных, а именно на совершение любых действий,
            связанных со сбором, систематизацией, регистрацией, накоплением,
            хранением, обновлением, изменением, восстановлением, уничтожением;
          </li>
          <li>
            - дает согласие в целях исполнения настоящего Договора на обработку
            таких категорий своих персональных данных: фамилии, имени, отчества;
            даты рождения; почтовых адресов (по месту регистрации и для
            контактов); сведений о гражданстве; номере основного документа,
            удостоверяющего личность Заказчика, сведений о дате выдачи
            указанного документа и выдавшем его органе; номерах телефонов;
            номерах факсов; адресах электронной почты (E-mail). По окончании
            обработки персональных данных Заказчика Исполнитель, по истечении
            предусмотренных действующим законодательством сроков хранения
            документов, содержащих персональные данные, обеспечивает их
            уничтожение;
          </li>
          <li>
            - гарантирует, что информация, им предоставленная, является полной,
            точной и достоверной. При предоставлении информации не нарушается
            действующее законодательство Российской Федерации, законные права и
            интересы третьих лиц.
          </li>
        </ul>
        <p>
          11.4. Обработка персональных данных Заказчика производится
          Исполнителем с использованием баз данных на территории Российской
          Федерации.
        </p>
        <p>
          11.5 Заказчик обязуется не осуществлять аудиозапись, видеозапись
          информации, материалов, консультаций, интернет-семинаров (вебинаров),
          предоставляемых Ресурсом в соответствии с условиями настоящего
          Договора, без специального письменного разрешения Исполнителя.
        </p>
        <p>
          11.6. Соглашаясь с условиями данного Договора, Заказчик (или
          представитель Заказчика, в т.ч. физическое лицо, должным образом
          уполномоченное заключить Договор от лица Заказчика) подтверждает и
          гарантирует Исполнителю, что заказчик:
        </p>
        <ul>
          <li>— полностью ознакомился с условиями Договора оферты; </li>
          <li>— полностью понимает предмет Договора оферты; </li>
          <li>
            — полностью понимает значение и последствия своих действий в
            отношении заключения и исполнения настоящего Договора.
          </li>
          <li>— Покупая тренировку вы подтверждаете что вы достаточно владеете русским языком письменным и устным ДЛЯ ПОНИМАНИЯ ВСЕГО РАНЕЕ УКАЗАННОГО  НА САЙТЕ и того что будет на тренировке.</li>
        </ul>
        <h2>12. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ: </h2>
        <p>
          <p>{getLegalDetails()}</p>
          {/* ИП Павловская Алина Сергеевна <br /> */}
          {/* Адрес: 443110, Самарская область, г. Самара, ул. Ново-Садовая, д. 19,
          кв. 86 <br />
          ОГРНИП 318631300101270 <br />
          ИНН 631184627974 <br />
          Расчетный счет 40802810700000662550 <br />
          Банк АО «Тинькофф Банк» <br />
          Юридический адрес банка: Москва, 123060, 1-й Волоколамский проезд, д.
          10, стр. 1 <br />
          Корр. счет банка 30101810145250000974 <br />
          ИНН банка 7710140679 <br />
          БИК банка 044525974 <br />
          Электронный адрес: ilovebodyflex@gmail.com */}
        </p>
      </div>
    </Modal>
  );
};
