import { useState } from "react";
import { Modal } from "../Modal";

declare global {
  interface Window { ym: any; }
}

const Head = ({
  name,
  isActive,
  id,
  setActive,
}: {
  name: string;
  id: number;
  isActive?: boolean;
  setActive: (id: number) => void;
}) => {
  return (
    <div
      onClick={() => setActive(id)}
      className="group flex flex-col justify-center content-center items-center cursor-pointer"
    >
      <div className="flex flex-col">
        <span className="prose-md font-bold lg:prose-2xl text-nowrap whitespace-nowrap">
          {name}
        </span>
      </div>
      <div
        className={`w-3/4 h-0.5 group-hover:bg-rose-500 ease-in-out duration-300 ${
          isActive ? "bg-rose-500" : "group-hover:bg-rose-500"
        }`}
      ></div>
    </div>
  );
};

export const Products = ({
  products,
  date,
  closed = false,
  closedTitle = "Набор закрыт, ожидайте новый",
  openModal = false,
}: any) => {
  const [activeId, setActiveId] = useState<number>(products[0].id);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(openModal);
  const [agree, setAgree] = useState<boolean>(false);

  const selectedProduct = products.find(
    (product: any) => product.id === activeId
  );

  return (
    <div className="mt-8 mb-8">
      <div className="mx-auto flex flex-col font-serif prose">
        <div className="flex flex-row justify-around">
          {products.map((product: any) => (
            <Head
              name={product.name}
              id={product.id}
              isActive={product.id === activeId}
              setActive={setActiveId}
            />
          ))}
        </div>
        <div className="mt-10">
          {
            products.find((product: any) => product.id === activeId)
              ?.description
          }
        </div>
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
          <div className="flex flex-col">
            <div>
              <h1 className="prose-2xl font-bold mb-2">Заполни заявку</h1>
            </div>
            <div>
              <form
                method="POST"
                action="http://crm.ilovebodyflex.ru/leads"
                className="flex flex-wrap flex-col gap-4"
              >
                <input
                  type="hidden"
                  name={`order[products][${selectedProduct.id}]`}
                  value="1"
                />
                <input
                  className="border border-gray-200 rounded-md placeholder:text-xs h-12 p-2 "
                  type="text"
                  name="contact[fields][name]"
                  placeholder="Имя"
                />
                <input
                  className="border border-gray-200 rounded-md placeholder:text-xs h-12 p-2 "
                  type="text"
                  name="contact[fields][last_name]"
                  placeholder="Фамилия"
                />
                <input
                  className="border border-gray-200 rounded-md placeholder:text-xs h-12 p-2 "
                  type="text"
                  name="contact[fields][email]"
                  placeholder="Email"
                />
                <input
                  className="border border-gray-200 rounded-md placeholder:text-xs h-12 p-2 "
                  type="text"
                  name="contact[fields][phone]"
                  placeholder="Телефон"
                />
                <input
                  className="border border-gray-200 rounded-md placeholder:text-xs h-12 p-2 "
                  type="text"
                  name="contact[fields][instagram]"
                  placeholder="Ник в Телеграм"
                />
                <div className="flex gap-4 mt-0 p-2">
                  <div className="flex items-center">
                    <input
                      className="w-4 h-4"
                      type="checkbox"
                      onChange={(e) => setAgree((v) => !v)}
                    />
                  </div>
                  <p className="prose-sm flex-grow-0 flex justify-start content-start items-start p-0 m-0">
                    Я согласен(а) с политикой конфиденциальности и договором
                    публичной офферты
                  </p>
                </div>
                <div className="flex justify-center items-center gap-4">
                  <div className="flex justify-center font-xl font-bold">
                    {selectedProduct.name}:
                  </div>
                  <div className="flex justify-center prose-2xl font-bold">
                    {selectedProduct.price}
                  </div>
                </div>
                <button
                  type="submit"
                  onClick={() => window.ym(90082507,'reachGoal', 'successfully')}
                  disabled={agree ? false : true}
                  className={`bg-red-500 text-white font-bold rounded-md p-2 pl-20 pr-20 uppercase text-xl ${
                    agree ? "bg-red-500" : "bg-gray-300"
                  }`}
                >
                  Записаться
                </button>
              </form>
            </div>
          </div>
        </Modal>

        <div className="flex flex-col items-center mt-10">
          {closed ? (
            <h2>{closedTitle}</h2>
          ) : (
            <>
              <div className="font-bold">• {date} •</div>
              {selectedProduct.oldPrice && <div className="prose-2xl font-bold line-through">{selectedProduct.oldPrice}</div>}
              <div className="prose-2xl font-bold">{selectedProduct.price}</div>
              <div className="mt-2">
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="bg-red-500 text-white w-56 font-bold rounded-md p-2 uppercase"
                >
                  Записаться
                </button>
                <a href="https://t.me/shpakmetod"><p className="flex items-center justify-center">У меня иностранная карта</p></a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
