import { createRef, forwardRef, useEffect, useRef, useState } from "react";

import { AiOutlineLeftCircle, AiOutlineRightCircle } from "react-icons/ai";
import { ReactComponent as Chat } from "assets/svg/chat.svg";
import { ReactComponent as Like } from "assets/svg/like.svg";
import { ReactComponent as Share } from "assets/svg/share.svg";

export type TestimonialType = {
  text: string | JSX.Element;
  name: string;
  avatar?: string;
  likes: number;
  show?: boolean;
};

const Testimonial = forwardRef<HTMLInputElement, TestimonialType>(
  (props, ref) => {
    const { text, avatar: avatarName, name, likes, show } = props;
    const avatar = require("assets/content/testimonials/" + avatarName);
    return (
      <div
        ref={ref}
        className={`flex flex-col border-2 rounded-xl select-none ${
          show ? "" : ""
        }`}
      >
        <div className="flex flex-grow-1 flex-col p-4 border-b-2 font-serif">
          <div className="flex">
            <div className="w-12 h-12">
              <img
                className="w-12 h-12 rounded-full mt-0 p-0 font-semibold"
                alt={name}
                src={avatar}
              />
            </div>
            <div className="flex items-center ml-2 font-semibold font-serif ">{name}</div>
          </div>
          <div className="flex font-light break-normal">{text}</div>
        </div>
        <div className="p-4">
          <div className="flex gap-3 items-center content-center mb-2">
            <Like /> <Chat /> <Share />
          </div>
          <div className="font-serif">{likes} отметок "Нравится"</div>
        </div>
      </div>
    );
  }
);

interface TestimonialsControlsProps {
  currentSlide: number;
  changeSlide: (slide: number) => void;
  quantity: number;
}

const TestimonialsControls = ({
  changeSlide,
  currentSlide,
  quantity,
}: TestimonialsControlsProps) => {
  const handleLeftClick = () => {
    if (currentSlide > 0) {
      changeSlide(currentSlide - 1);
    }
  };

  const handleRightClick = () => {
    if (quantity > currentSlide + 1) {
      changeSlide(currentSlide + 1);
    }
  };

  const carouselButtonClass =
    "absolute flex top-20 items-center justify-center p-2 rounded-full border-2 z-10 border-transparent w-14 h-14 focus:outline-none focus:shadow-outline";
  const carouselButtonLeftClass =
    carouselButtonClass +
    " left-0" +
    (currentSlide === 0 ? "  opacity-10" : "");
  const carouselButtonRightClass =
    carouselButtonClass +
    " right-0" +
    (currentSlide === quantity - 1 ? " opacity-10" : "");

  return (
    <div>
      <button className={carouselButtonLeftClass} onClick={handleLeftClick}>
        <AiOutlineLeftCircle className="w-14 h-14 text-gray-200" />
      </button>
      <button className={carouselButtonRightClass} onClick={handleRightClick}>
        <AiOutlineRightCircle className="w-14 h-14 text-gray-200" />
      </button>
    </div>
  );
};
export const Testimonials = ({
  testimonials,
}: {
  testimonials: TestimonialType[];
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [currentSlideHeight, setCurrentSlideHeight] = useState<
    number | null | undefined
  >();
  const refs = useRef(testimonials.map(() => createRef<HTMLInputElement>()));

  useEffect(() => {
    refs.current = testimonials.map(() => createRef<HTMLInputElement>());
  }, [testimonials, refs]);

  const currentRef = refs.current[currentSlide];

  useEffect(() => {
    setCurrentSlideHeight(currentRef.current?.clientHeight);
  }, [currentSlide, currentRef]);

  const containerClass = "container mx-auto mb-7 prose";
  const carouselClass = "overflow-hidden relative";
  const carouselInnerClass = "transition duration-500 flex";
  const carouselItemClass = "flex items-start shrink-0 w-full pr-14 pl-14";

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.changedTouches[0];
    const deltaX = touch.clientX - touchStartX;
    if (deltaX === 0) return;
    if (deltaX > 0) {
      if (currentSlide === 0) return;
      setCurrentSlide(currentSlide - 1);
    } else {
      if (currentSlide >= testimonials.length - 1) return;
      setCurrentSlide(currentSlide + 1);
    }
  };

  return (
    <div className={containerClass}>
      <div
        className={carouselClass}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <TestimonialsControls
          changeSlide={setCurrentSlide}
          currentSlide={currentSlide}
          quantity={testimonials.length}
        />
        <div
          className={carouselInnerClass}
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
          }}
        >
          <div className="w-12" />
          {testimonials.map((item, i) => (
            <div
              key={item.name}
              className={carouselItemClass}
              style={{
                maxHeight: currentSlideHeight ? currentSlideHeight + 10 : 0,
                transition: "max-height 0.25s ease-in",
              }}
            >
              <Testimonial
                ref={refs.current[i]}
                {...item}
                show={currentSlide === i}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
