import { ChatMessages } from "components/ChatMessages";
import { ChatMessageType } from "components/ChatMessages/ChatMessages";
import { Testimonials } from "components/Testimonials";
import { TestimonialType } from "components/Testimonials/Testimonials";
import { TextSection } from "components/TextSection";
import { HeroImageContainer } from "components/HeroImageContainer";
import { ReactComponent as BodySVG } from "assets/content/results/body.svg";
import { ReactComponent as HeartSVG } from "assets/content/results/heart.svg";
import { ReactComponent as LeafsSVG } from "assets/content/results/leafs.svg";

import { ReactComponent as ForkAndSVG } from "assets/content/results/fork_spoon.svg";
import { ReactComponent as PregnantSVG } from "assets/content/results/pregnant.svg";
import { ReactComponent as BackSideSVG } from "assets/content/results/back_side.svg";
import { ReactComponent as LungsSVG } from "assets/content/results/lungs.svg";
import { ReactComponent as StaminaSVG } from "assets/content/results/stamina.svg";
import { ReactComponent as ConsultationSVG } from "assets/svg/consultation.svg";
import { Header } from "components/Header";
import { useRef } from "react";
import { Products } from "components/Products";
import { Footer } from "components/Footer";

const intro = (
  <>
    {/* <h1 className="font-bold text-5xl">
      Одна цель — сделать из вас{" "}
      <span className="text-rose-500 block">@хуенных королев</span>
    </h1> */}
    <p>
      Каждая тренировка с Алиной уникальна, каждый раз – это разные техники дыхания
      и новый комплекс упражнений, который подходит как{" "}
      <strong>для новичков, так и для продвинутых пользователей</strong>. Ни
      одна тренировка и ни один комплекс не повторяются, поэтому так важно
      участвовать в каждой – это системный подход к вашему телу!
    </p>
    <p>
      <strong>
        #ШпакМетод_Оксисайз – агрессивная тренировка, сочетающая упражнения в статодинамике с дыхательными техниками.
        Благодаря этому кровь намного интенсивнее насыщается кислородом, во
        время упражнений на определенную зону он направляется именно туда и
        сжигает жировые отложения, а силовая нагрузка добавляет мышечный рельеф
        и тонус всему телу. Это все за 20 минут в день! Данная техника
        направлена на проработку рельефа тела и привычнее тем, кто знаком с
        классическим фитнесом, входит в обязательный годовой комплекс тренировок
        каждой моей королевы.
      </strong>
    </p>
  </>
);

const chatMessages: ChatMessageType[] = [
  {
    text: "Можно ли заниматься #бодифлекс_с_алинойшпак / #оксисайз_с_алинойшпак во время беременности?",
  },
  {
    author: "Алина Шпак",
    text: "Нельзя",
  },
  {
    text: "Когда можно начинать заниматься после родов?",
  },
  {
    author: "Алина Шпак",
    text: "Если роды прошли без осложнений, начинать занятия можно через 4-6 недель. В этот период не жди быстрых результатов: пока гормональный фон не стабилизировался, похудеть достаточно сложно.",
  },
  {
    text: "Можно ли совмещать с другими тренировками?",
  },
  {
    author: "Алина Шпак",
    text: "УТРОМ ВСТАЛА, подышала, потом делай, что хочешь! Можно совмещать с другими фитнес-дисциплинами, но не заменяя, а лишь дополняя.",
  },
  {
    text: "Что делать, если во время трени кружится голова?",
  },
  {
    text: "Какие противопоказания?",
  },
  {
    author: "Алина Шпак",
    text: "Смотри на сайте в разделе ПРОТИВОПОКАЗАНИЯ! Перед началом тренировок проконсультируйся с врачом.",
  },
  {
    text: "Что делать, если после занятий вес стоит на месте или увеличивается?",
  },
  {
    author: "Алина Шпак",
    text: "Вес и объёмы это разные вещи. Мы ориентируемся НА РАЗМЕР ОДЕЖДЫ, т.е на ОБЪЁМЫ. Занимайся регулярно и выбери технику, эффективную именно для ТЕБЯ 😘",
  },
];

const liAgreeStyle =
  "before:content-['✅'] before:-left-6 before:text-xl before:absolute relative";

const howPass = (
  <div>
    <h2>
      7 дней каждое утро в 8:00 по Москве выходит урок, состоящий из 3-х частей:
    </h2>
    <ul>
      <li className={liAgreeStyle}>детальный разбор новых упражнений;</li>
      <li className={liAgreeStyle}>тренировка с данными упражнениями;</li>
    </ul>
    <ul>
      <li className={liAgreeStyle}>
        лекции от дерматолога-косметолога с медицинским стажем 27 лет, с темой: как выглядеть моложе своих лет.
      </li>
    </ul>
    <ul>
      <li className={liAgreeStyle}>
        Доступ к информации сохраняется на 2 недели со дня начала тренировки.
        Подробный разбор дыхания в <strong>первый день</strong> тренировки.
      </li>
    </ul>
    <h3>Что нужно для тренировки:</h3>
    <ul>
      <li className={liAgreeStyle}>
        коврик или большое полотенце для занятий;
      </li>
      <li className={liAgreeStyle}>
        антицеллюлитный крем, крем для груди, маски для лица, патчи – по
        желанию;
      </li>
      <li className={liAgreeStyle}>
        одежду для тренировки - от образа голышом до спортивного костюма
        (стирать каждый день!), обувь не нужна;
      </li>
      <li className={liAgreeStyle}>
        комфортное место для тренировки, рекомендуется выполнять упражнения
        перед зеркалом либо записывать себя на камеру, чтобы самостоятельно
        контролировать технику выполнения. Ваша жопа в ваших руках!
      </li>
    </ul>
  </div>
);

const liExlamationStyle =
  "before:content-['❗'] before:-left-6 before:text-xl before:absolute relative";

const rulesContent = (
  <div>
    <h2>Правила участия:</h2>
    <p>
      <strong>
        ШАГ 1. Оплата происходит через сервис Robokassa на сайте
        www.ilovebodyflex.online.
      </strong>
    </p>
    <p>
      Внимательно указывайте свои личные данные в анкете на сайте, c
      неправильными данными обратная связь невозможна!
    </p>
    <p>
      <strong>
        Шаг 2. После оплаты вам нужно отправить запросы в{" "}
        <a href="https://t.me/+FGWwCnFT5rcwNGYy">закрытый Телеграм-канал</a>,
        там проходит тренировка.
      </strong>
    </p>
    <p>❗ Добавление в закрытый Телеграм-канал в течение 2-х дней.</p>
    <p>
      При возникновении проблемы с добавлением или оплатой, можно написать в
      Телеграм <a href="https://t.me/shpakmetod">@shpakmetod</a>. Техническая
      поддержка работает с 9:00 до 22:00 по Москве.
    </p>
    <h2 className="block text-rose-500">Важно:</h2>
    <ul className="list-none">
      <li className={liExlamationStyle}>
        После начала тренировки оплата не возвращается и не переносится ни по
        каким причинам.
      </li>
      <li className={liExlamationStyle}>
        Моя тренировка – мои правила! Что говорить, как рассуждать, темы лекций и
        все остальное на тренировке я определяю сама. Ваши советы и рекомендации
        прошу оставить при себе.
      </li>
      <li className={liExlamationStyle}>Скачайте приложение Telegram.</li>
      <li className={liExlamationStyle}>
        Заранее будьте уверены в вашем доступе к интернету, его скорости.
      </li>
      <li className={liExlamationStyle}>
        Внимательно ознакомьтесь с противопоказаниями.
      </li>
      <li className={liExlamationStyle}>
        Копирование материала тренировки, его распространение запрещено и
        преследуется законом.
      </li>
      <li className={liExlamationStyle}>Детальный разбор новых упражнений</li>
      <li className={liExlamationStyle}>
        Придерживаемся принципа персональной ответственности за свое здоровье.
      </li>
    </ul>
  </div>
);

const testimonialsText = (
  <div>
    <h2>Отзывы моих королев</h2>
    <p>#ШпакМетод настроен на результат</p>
    <p>
      <span className="font-bold text-rose-500">#КоролевыБодифлекса</span>{" "}
      начинают выкидывать и раздавать одежду, которая стала велика, когда они
      едят то, что нравится и живут в кайф. Потом пишут, что худеют несмотря ни
      на что, когда возвращаются из отпуска меньше на размер, когда не парятся,
      что бы такого съесть, чтобы похудеть!
    </p>
    <p>
      Потому что <span className="font-bold text-rose-500">#ШпакМетод</span> на
      их мировосприятие, тело и <strong>результат</strong>. Здесь{" "}
      <strong>все без цензуры, воды, четко и по факту!</strong> Зачем сюсюканья,
      если это не приблизит вас к телу без жира?
    </p>
  </div>
);

const testimonials: TestimonialType[] = [
  {
    name: "daryadoshenko",
    avatar: "review-avatar-1.png",
    text: (
      <p>
        👍легко и очень просто, главное делать правильно) ❤️мне очень нравится)
        буду ждать теперь эфир с техникой пожёстче)😁 я после зарядки как
        пропеллер ношусь ...) 😘 благодарю
      </p>
    ),
    likes: 27,
  },
  {
    name: "smartcosmetolog",
    avatar: "ava-8.jpeg",
    text: (
      <p>
        Я начала с Алиной работать в августе: я маленькая и поэтому каждый кг
        виден 🙈 к сегодняшнему дню -3,5 кг 💪 в объёмах только под грудью -8 см
        🔥🔥🔥 Теперь у меня задача ещё -4 кг с оксисайз 👌 Мне нравится подход
        Алины: всё просто, делайте, как говорю, главное каждый день))) Реально
        всё работает — первый результат виден через несколько дней 💪 Это
        ГЕНИАЛЬНАЯ СИСТЕМА 🥇"
      </p>
    ),
    likes: 159,
  },
  {
    name: "katri_na93",
    avatar: "ava-2.jpeg",
    text: (
      <p>
        Не так-то просто уложить всю благодарность в одно сообщение 😍 началось
        с того, что после родов, я сильно поправилась, хоть кг и показывали
        примерно такие же цифры как до беременности, но объемы были просто
        огромными! Пол года я наблюдала за Вашей страничкой, сначала одним
        глазом, потом ждала, когда начнётся тренировка! Я заранее настраивала себя
        на плохой результат, ведь маленький ручной ребёнок весь день со мной, да
        и сил не было совсем, пока малышка спала, я тоже спала, и ещё безумно
        спина болела, дочку не могла поднимать, если только со слезами. После
        тренировки, кстати, собиралась к остеопату насчёт спины. Вот началась
        тренировка, нашла в себе силы не спать, а смотреть эфир, делала все с
        опозданием на один день. А дальше началось нечто... на третий день
        тренировки я проснулась без боли в спине... пишу и прям плачу... я на
        протяжении нескольких месяцев не могла даже лежать без боли, а тут
        такое...
      </p>
    ),
    likes: 137,
  },
  {
    name: "alinkashpak",
    avatar: "ava-1.jpeg",
    text: (
      <p>
        100% работает, а фраза «Ваша жопа в ваших рука» спасёт мир!!! Алина, не
        перестаю вас благодарить, жир тает, а энергия только прибавляется,
        участвовала уже в 2-х тренировках и жду 3-ю!!! ❤️❤️❤️😘
      </p>
    ),
    likes: 152,
  },
  {
    name: "la_skava",
    avatar: "ava-3.jpeg",
    text: (
      <p>
        Алииииииинааа! Это невероятно!!! Я в шоке, поэтому ступор в словах моей
        благодарности!!! Но! Три недели моей первой тренировки с тобой и
        тааакиие результаты!!! Грудь без изменений! Талия минус 4 см, живот
        минус 3, бёдра минус 2!!!! А ноги! Ноги!!! Я уже забыла какой они формы,
        мои прекрасные длинные ноги!! Постоянно отечные и жирноватые((( При
        росте 175 и весе 68. Сейчас объёмы 93-74-100!!!! Я навеки с Вами! Всё
        работает!!
      </p>
    ),
    likes: 152,
  },
  {
    name: "talkree78",
    avatar: "ava-4.jpeg",
    text: (
      <p>
        Привет дышу с тобой уже Неделю и не знаю или это от головы или реально
        всё так классно, живот мой уходит, хотя я пью пиво в выхи и не могу
        забыть про него, но уже не утро без дыхания, как наркотик. Встаю и бегом
        дышать с мелкой, она за мной всё повторяет 😊 Надеюсь скоро стать худой
        и подтянутой 😊 😛
      </p>
    ),
    likes: 189,
  },
  {
    name: "tan_tan",
    avatar: "ava-5.jpeg",
    text: (
      <p>
        Алина, благодарю за тренировку ❤️ Более понятного объяснения техники и
        упражнений я не слышала нигде ещё ✊ У меня тариф без обратной связи, но
        я считаю, что всё более, чем понятно 👌👌👌 Подписана давно на тебя,
        пробовала делать и #шпакметод_зарядка и упражнения в свободном доступе
        по бодифлексу, думала всё уже знаю ✌️ такая деловая, хрен там 🤚🙈🙊 В
        тренировке я совершенно по-другому начала дышать, более осознанно все 5
        этапов, прям супер 💫 Помогают твои объяснения деталей мелких, вроде как
        итак понятно, но вот именно там подкрутил, тут голову наклонил и всё,
        совсем другое дело, совсем другой результат, и живот лучше уходит под
        рёбра и на пятом этапе хочется вдохнуть как положено, а то ж когда
        самостоятельно делала, думала, ну что тяжёлого-то, задержать на 10 сек.,
        фи, я могу и дольше, ага, сейчас до 8-9 секунд дотянуть бы 😐 В общем, я
        в восторге полнейшем 👏 Хочу теперь подышать с тобой оффлайн 💫💫💫
      </p>
    ),
    likes: 189,
  },
  {
    name: "nenris77",
    avatar: "ava-6.jpeg",
    text: (
      <p>
        И вот сейчас: качество тела изменилось, целлюлит ушёл, влезла во все
        вещи. Работы ещё немного и я навеки Ваша... 💙💙💙 пью вино и ем всё,
        что хочу, дышу по утрам и слушаю чашу... доверяю и верю и дальше
        наблюдаю... как изменится моё тело, как изменюсь я и всё вокруг...
        дыхательные техники — это не только про жопу, это про всю жизнь... я
        выдохнула благодаря Вам Спасибо 🙏
      </p>
    ),
    likes: 189,
  },
  {
    name: "tatkach66",
    avatar: "ava-7.jpeg",
    text: (
      <p>
        Тренировка!! Просто супер! Мне очень нравится! Бодифлекс тоже интересен! Но
        оксисайз просто 🔥 !!! Результаты лично у меня более ощутимее!! Всё тело
        ломит от мышечной боли. Очень люблю такое состояние. Спасибо огромное!!
        Ждём продолжения!
      </p>
    ),
    likes: 189,
  },
];
const liRestrictedStyle =
  "before:content-['❌'] before:-left-6 before:text-xl before:absolute relative";

const contraindication = (
  <div>
    <h2>Противопоказания</h2>
    <h4>Основные противопоказания к #ШпакМетод_Оксисайз:</h4>
    <ul className="list-none">
      <li className={liRestrictedStyle}> беременность,</li>
      <li className={liRestrictedStyle}> cердечно-сосудистые заболевания,</li>
      <li className={liRestrictedStyle}>киста яичника,</li>
      <li className={liRestrictedStyle}> онкология, </li>
      <li className={liRestrictedStyle}> миоматические узлы,</li>
      <li className={liRestrictedStyle}> эпилепсия,</li>
      <li className={liRestrictedStyle}> грыжи пищевода, </li>
      <li className={liRestrictedStyle}> заболевания органов зрения,</li>
      <li className={liRestrictedStyle}> легочная гипертензия, </li>
      <li className={liRestrictedStyle}>
        заболевания почек (нефроптоз, гломерулонефрит),
      </li>
      <li className={liRestrictedStyle}>
        наличие полостной операции менее, чем полгода назад (раньше только после
        разрешения лечащего врача),
      </li>
      <li className={liRestrictedStyle}>
        аневризма аорты или сосудов головного мозга,
      </li>
      <li className={liRestrictedStyle}>
        {" "}
        повышенное внутричерепное давление.
      </li>
    </ul>
  </div>
);

const byAgreementWithTheDoctor = (
  <div className="pb-12">
    <h2>По согласованию с врачом:</h2>
    <ul className="list-none">
      <li className={liRestrictedStyle}> гипертония 1 и 2 степени,</li>
      <li className={liRestrictedStyle}>нефрэктомия (удаленная почка),</li>
      <li className={liRestrictedStyle}>
        серьезные проблемы со зрением (высокая степень близорукости, глаукома и
        т. п.),
      </li>
      <li className={liRestrictedStyle}>
        любые заболевания и слабость сосудов (о последнем свидетельствует
        постоянное возникновение синяков, гематом, «лопание» сосудов, например,
        сосудов глаз, носовое кровотечение), проблемы с сосудами головного
        мозга,
      </li>
      <li className={liRestrictedStyle}>
        мочекаменная и желчекаменная болезнь в период обострения,
      </li>
      <li className={liRestrictedStyle}>травмы и грыжи позвоночника.</li>
    </ul>
    <div className="mt-10">
      <ConsultationSVG className="w-full" />
    </div>
  </div>
);

const footerContent = (
  <div className="flex justify-around font-serif text-gray-400">
    <div>
      <div>П МОКШИН СЕРГЕЙ НИКОЛАЕВИЧ</div>
      <div>ИНН 631102456008</div>
    </div>
    <div>
      <div>Email: ilovebodyflax@gmail.com</div> <div>Договор оферты</div>
    </div>
  </div>
);

const resultIconStyle =
  "text-white text-sm flex flex-col items-center gap-4 w-52 mb-10";
const textSpanStyle = "flex justify-center text-center items-center text-sm";

const resultsContent = (
  <div className="prose min-w-full gap-x-8 bg-rose-500 p-4 text-center">
    <h1 className="mt-20 font-serif text-white">
      Результаты от техники Оксисайз:
    </h1>
    <div className="mt-10 flex flex-wrap items-center justify-around">
      <div className={resultIconStyle}>
        <BodySVG />
        <div>
          <span className={textSpanStyle}>
            Создает рельеф и тонус всему телу
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <HeartSVG />
        <div>
          <span className={textSpanStyle}>
            Улучшает кровообращение и сердечный ритм
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <LeafsSVG />
        <div>
          <span className={textSpanStyle}>Обладает мощным детокс эффектом</span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <PregnantSVG />
        <div>
          <span className={textSpanStyle}>
            Быстро восстанавливает после родов
          </span>
        </div>
      </div>
    </div>
    <div className="flex flex-wrap items-center justify-around">
      <div className={resultIconStyle}>
        <BackSideSVG />
        <div>
          <span className={textSpanStyle}>
            Убирает лишние килограммы и сантиметры, дает эффект "сушки" тела
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <ForkAndSVG />
        <div>
          <span className={textSpanStyle}>Помогает контролировать аппетит</span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <LungsSVG />
        <div>
          <span className={textSpanStyle}>
            Улучшает работу всех органов и систем организма{" "}
          </span>
        </div>
      </div>
      <div className={resultIconStyle}>
        <StaminaSVG />
        <div>
          <span className={textSpanStyle}>Развивает выносливость</span>
        </div>
      </div>
    </div>
  </div>
);

const Main = ({ scrollTo }: any) => {
  return (
    <>
      <div className="relative bg-red-100 lg:hidden">
        <div className="clip-hero-bg-white relative h-screen bg-white sm:flex">
          <div className="clip-hero-bg-mobile absolute flex h-full w-full bg-heroBgMobile bg-cover bg-center bg-no-repeat"></div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div className="font-ristretto absolute bottom-12 flex w-full flex-col content-center items-center justify-center">
            <div className="text-red-500 font-semibold text-8xl leading-none">#ШпакМетод</div><div className="text-cyan-600 font-semibold text-8xl leading-none">_Оксисайз</div>
            <div className="">
              <div
                onClick={() => scrollTo("buy")}
                className="text-xl mt-4 rounded-md bg-red-500 p-3 pl-20 pr-20 uppercase text-white"
              >
                Купить
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden h-screen w-auto bg-rose-200 md:hidden lg:visible lg:flex">
        <div className="clip-header-white relative flex w-2/5 bg-white bg-cover bg-center bg-no-repeat">
          <div className="asbolute clip-header z-10 flex w-full bg-red-100 bg-alinaLay bg-cover bg-center bg-no-repeat"></div>
        </div>
        <div className="mx-auto flex	 w-1/2 flex-wrap content-center items-center justify-center">
          <div className="mr-4 flex flex-grow-0 font-ristretto">
            <div className="text-red-500 font-semibold text-8xl leading-none">#ШпакМетод</div><div className="text-cyan-600 font-semibold text-8xl leading-none">_Оксисайз</div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Oxycise = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const introRef = useRef<HTMLDivElement>(null);
  const qaRef = useRef<HTMLDivElement>(null);
  const howRef = useRef<HTMLDivElement>(null);
  const rulesRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const contraindicationRef = useRef<HTMLDivElement>(null);
  const buyRef = useRef<HTMLDivElement>(null);

  const scrollTo = (name: string) => {
    if (name === "top") window.scrollTo(0, introRef?.current?.offsetTop || 0);
    if (name === "intro") window.scrollTo(0, introRef?.current?.offsetTop || 0);
    if (name === "qa") window?.scrollTo(0, qaRef?.current?.offsetTop || 0);
    if (name === "how") window?.scrollTo(0, howRef?.current?.offsetTop || 0);
    if (name === "buy") window?.scrollTo(0, howRef?.current?.offsetTop || 0);
    if (name === "rules")
      window?.scrollTo(0, rulesRef?.current?.offsetTop || 0);
    if (name === "testimonials")
      window?.scrollTo(0, reviewsRef?.current?.offsetTop || 0);
    if (name === "contraindication")
      window?.scrollTo(0, contraindicationRef?.current?.offsetTop || 0);
  };

  const products = [
    {
      id: 25,
      name: "Стандарт",
      description: (
        <ul>
          <li className="font-bold">
            доступ к закрытому{" "}
            <a href="https://t.me/+FGWwCnFT5rcwNGYy">Телеграм-каналу</a> на 14 дней, в котором проходит тренировка
          </li>
          <li className="font-bold">
            без обратной связи и проверки домашнего задания.
          </li>
        </ul>
      ),
      price: "8 000 ₽",
      // oldPrice: "8 000 ₽",
    },
    {
      id: 26,
      name: "Стандарт плюс",
      description: (
        <ul>
          <li className="font-bold">
            доступ к закрытому{" "}
            <a href="https://t.me/+FGWwCnFT5rcwNGYy">Телеграм-каналу</a> на 14 дней, в котором проходит тренировка
          </li>
          <li className="font-bold">
            ПРОВЕРКА ТЕХНИКИ ДЫХАНИЯ В ГРУППЕ в wats’app в течение 32 часов
            после начала тренировки Далее без обратной связи и проверки домашнего
            задания
          </li>
          {/* <li className="font-bold">Жесткий решим и тотальный контроль</li> */}
        </ul>
      ),
      price: "10 000 ₽",
      // oldPrice: "15 000 ₽",
    },
    {
      id: 27,
      name: "VIP",
      description: (
        <ul>
          <li className="font-bold">
            доступ к закрытому{" "}
            <a href="https://t.me/+FGWwCnFT5rcwNGYy">Телеграм-каналу</a> на 14 дней, в котором проходит тренировка
          </li>
          <li className="font-bold">
            проверка всех домашних заданий в общем VIP чате в WhatsApp (с 15 июля по 22 июля)
          </li>
          <li className="font-bold">жесткий режим и тотальный контроль.</li>
        </ul>
      ),
      price: "15 000 ₽",
      // oldPrice: "20 000 ₽",
    },
    {
      id: 17,
      name: "VIP+",
      description: (
        <ul>
          <li className="font-bold">
            доступ к закрытому{" "}
            <a href="https://t.me/+FGWwCnFT5rcwNGYy">Телеграм-каналу</a> на 14 дней, в котором проходит тренировка
          </li>
          <li className="font-bold">
            проверка домашних заданий <strong>лично Алиной Шпак</strong> в
            закрытом чате в WhatsApp
          </li>
          <li className="font-bold">жесткий режим и тотальный контроль.</li>
        </ul>
      ),
      price: "100 000 ₽",
    },
  ];

  return (
    <div ref={topRef}>
      <Header scrollTo={scrollTo} ref={headerRef} />
      <Main scrollTo={scrollTo} />
      <div ref={introRef} />
      <TextSection content={intro} />
      {resultsContent}
      <div ref={qaRef} />
      <HeroImageContainer imageName="qa.png" />
      <ChatMessages messages={chatMessages} />
      <div ref={howRef} />
      <HeroImageContainer imageName="how-pass.png" />
      <TextSection content={howPass} />
      <div ref={rulesRef} />
      <HeroImageContainer imageName="rules.png" />
      <TextSection content={rulesContent} />
      <div ref={reviewsRef} />
      <HeroImageContainer imageName="testimonials.png" />
      <TextSection content={testimonialsText} />
      <Testimonials testimonials={testimonials} />
      <div className="h-16 bg-separator bg-repeat-x" />
      <div ref={contraindicationRef} />
      <TextSection content={contraindication} />
      <TextSection
        className="bg-gray-100 pt-4"
        content={byAgreementWithTheDoctor}
      />
      <div ref={buyRef} />
      <Products
        active={true}
        date={"15 июля 2024"}
        products={products}
        closed={false}
      />
      <Footer />
      {/* <div className="prose mx-auto p-8">{footerContent}</div> */}
    </div>
  );
};
