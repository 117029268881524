import avaImage from './ava.png';

export type ChatMessageType = {
  text: string;
  author?: string | undefined;
};

const GuestChatMessage = ({ message }: { message: ChatMessageType }) => {
  return (
    <div className="m-6 flex justify-end">
      <div className="rounded-full max-w-xl bg-slate-50 p-6 text-slate-500 font-serif">
        {message.text}
      </div>
    </div>
  );
};

const AuthorChatMessage = ({ message }: { message: ChatMessageType }) => {
  return (
    <div className="m-6 flex justify-start items-center gap-8">
			<img className="w-16 h-16" alt="Алина Шпак" src={avaImage} />
      <div className="rounded-3xl max-w-lg p-6 text-slate-500 border-2 font-serif">
        {message.text}
      </div>
    </div>
  );
};

const ChatMessage = ({ message }: { message: ChatMessageType }) => {
  return ( message.author ? <AuthorChatMessage message={message} /> : <GuestChatMessage message={message} /> );
};

export const ChatMessages = ({ messages }: { messages: ChatMessageType[] }) => {
  return (
    <div className="container prose mx-auto px-4">
      {messages.map((message, index) => (
        <ChatMessage key={index} message={message} />
      ))}
    </div>
  );
};
