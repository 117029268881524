import { TextSection } from "components/TextSection";
import { ReactComponent as ConsultationSVG } from "assets/svg/consultation.svg";

const liRestrictedStyle =
  "before:content-['❌'] before:-left-6 before:text-xl before:absolute relative";
  
export const Contraindications = () => {
    const contraindication = (
      <div>
        <h2>Противопоказания</h2>
        <h4>Основные противопоказания к #Бодифлекс_c_АлинойШпак:</h4>
        <ul className="list-none">
          <li className={liRestrictedStyle}> Беременность 🤰</li>
          <li className={liRestrictedStyle}>
            {" "}
            Язва желудка (в период обострения)
          </li>
          <li className={liRestrictedStyle}>
            Любые заболевания сердца: сердечная недостаточность, аритмия,
            тахикардия, перенесенный инфаркт, ишемическая болезнь сердца и т.п.
          </li>
          <li className={liRestrictedStyle}> Эпилепсия</li>
          <li className={liRestrictedStyle}> Диафрагмальная грыжа</li>
        </ul>
        <div className="mt-10">
          <ConsultationSVG className="w-full" />
        </div>
      </div>
    );
    return (
      <TextSection
        className="bg-gray-100 pt-4"
        margin="mt-0"
        content={contraindication}
      />
    );
  };
  