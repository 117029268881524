import { Products } from "components/Products";

const charityProducts = [
    {
        id: 22,
        name: "Благотворительность",
        description: (
          <></>
        ),
        price: "333 ₽",
      },
]
export const Charity = () => {
  return (
    <div className="prose mx-auto text-center mt-10">
      <h1>Благотворительность</h1>
      <Products products={charityProducts} openModal />
    </div>
  );
};
