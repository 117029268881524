import { ReactComponent as TitleProFirst } from "assets/svg/header-pro-title-first.svg";
import { ReactComponent as TitleProSecond } from "assets/svg/header-pro-title-second.svg";
import { TextSection } from "components/TextSection";
import { useRef } from "react";

import { ReactComponent as BodySVG } from "assets/content/results/body.svg";
import { ReactComponent as ChatSVG } from "assets/content/results/chat.svg";
import { ReactComponent as PostureSVG } from "assets/content/results/posture.svg";
import { ReactComponent as ForkAndSpoonSVG } from "assets/content/results/fork_spoon.svg";
import { ReactComponent as StandSVG } from "assets/content/results/stand.svg";
import { ReactComponent as FlashSVG } from "assets/content/results/flash.svg";
import { ReactComponent as BackSideSVG } from "assets/content/results/back_side.svg";
import { ReactComponent as EdemaSVG } from "assets/content/results/edema.svg";
import { ReactComponent as YogaSVG } from "assets/content/results/yoga.svg";
import { ReactComponent as ImmuneSVG } from "assets/content/results/immune.svg";

import { HeroImageContainer } from "components/HeroImageContainer";
import { Testimonials } from "components/Testimonials";
import { Footer } from "components/Footer";
import { Products } from "components/Products";
import { HeaderPro } from "components/HeaderPro";
import { Contraindications } from "components/Contraindications";

const HeroSection = ({ scrollTo }: { scrollTo: (name: string) => void }) => {
  return (
    <>
      <div className="relative bg-red-100 lg:hidden">
        <div className="clip-hero-bg-white relative h-screen bg-white sm:flex">
          <div className="clip-hero-bg-mobile absolute flex h-full w-full bg-heroBgMobile bg-cover bg-center bg-no-repeat"></div>
        </div>
        <div className="absolute bottom-6 flex h-1/2 w-full flex-col content-center items-center justify-end font-ristretto">
          <div className="text-8xl font-semibold leading-none text-red-500">
            #ШпакМетод
          </div>
          <div className="text-8xl font-semibold leading-none text-cyan-600">
            &nbsp;PRO
          </div>
          <div
            onClick={() => scrollTo("buy")}
            className="text-xl mt-4 w-52 cursor-pointer rounded-md bg-red-500 p-3 text-center uppercase text-white"
          >
            Купить
          </div>
        </div>
      </div>
      <div className="hidden h-screen w-auto bg-rose-200 md:hidden lg:visible lg:flex">
        <div className="clip-header-white relative flex w-2/5 bg-white bg-cover bg-center bg-no-repeat">
          <div className="clip-header z-10 flex w-full bg-red-100 bg-alinaLay bg-cover bg-center bg-no-repeat"></div>
        </div>
        <div className="mx-auto flex	w-1/2 flex-wrap content-center items-center justify-center gap-8">
          <div className="mr-4 mt-8 flex flex-grow-0 gap-2 font-ristretto">
            <div className="text-8xl font-semibold leading-none text-red-500">
              #ШпакМетод
            </div>
            <div className="text-8xl font-semibold leading-none text-cyan-600">
              _PRO
            </div>
          </div>
          <div
            onClick={() => scrollTo("buy")}
            className="text-xl w-52 cursor-pointer rounded-md bg-red-500 p-3 text-center uppercase text-white"
          >
            Купить
          </div>
        </div>
      </div>
    </>
  );
};

const liAgreeStyle =
  "before:content-['✅'] before:-left-6 before:text-xl before:absolute relative";

const liArrowStyle =
  "before:content-['➡️'] before:-left-6 before:text-xl before:absolute relative";

const liFireWorkStyle =
  "before:content-['💥'] before:-left-6 before:text-xl before:absolute relative";

const liExclamationStyle =
  "before:content-['ℹ️'] before:-left-6 before:text-xl before:absolute relative";

const AboutSection = () => {
  const content = (
    <div>
      <h1>
        Тренировка <span className="text-rose-500">#ШпакМетод Pro</span>
      </h1>
      <p>
        <strong>
          Каждая моя тренировка уникальна - новая техника дыхания и новый комплекс
          упражнений, поэтому так важно участвовать в каждой - это системный
          подход к вашему телу!
        </strong>
      </p>

      <p>
        Кислород - это не только главный элемент жизнеобеспечения, но и ключевой
        аспект, определяющий КАЧЕСТВО жизни! С помощью дыхания мы прокачиваем
        энергию, корректируем физическое и эмоциональное состояния.
      </p>

      <p>
        Тренировка #ШпакМетод Pro представляет собой 15 минутную комплексную
        тренировку, в которой мы используем диафрагмальное дыхание 2х видов:
      </p>

      <ul className="list">
        <li className={liAgreeStyle}>
          с задержкой дыхания (кислородное голодание позволяет нашему организму
          быстро избавиться от всего лишнего);
        </li>
        <li className={liAgreeStyle}>без задержки дыхания.</li>
      </ul>
      <p>
        К каждому виду мы добавляем динамичные и статичные упражнения,
        направленные на сжигание жира, подтягивание кожи и проработку
        «проблемных зон» (живот, бёдра, талия, ноги и т.д.). Тренировка
        активизирует биохимические процессы организма и тем самым повышает
        усвоение жира организмом на энергетические цели, повышая уровень
        энергии.{" "}
      </p>

      <p>
        <strong>
          На каком бы уровне жизни вы не находились, энергетические практики и
          работа с энергией - это фундаментальные основы и законы жизни.
        </strong>
      </p>

      <h2>Что нас ждет на этой тренировке #Шпакметод_pro ? 🔥</h2>

      <ul className="list">
        <li className={liAgreeStyle}>
          Эффективный комплекс упражнения на все тело, который поможет тебе
          убрать лишние объемы и придаст красивую форму ногам, рукам и ягодицам.
        </li>
        <li className={liAgreeStyle}>
          Приглашенный спикер: психолог-сексолог ( 18+)
        </li>
        {/* <p>Урок легендарного курса #ПеретряхнемКосметичку_Шпак 💄</p>
        <li className={liExclamationStyle}>
          Формирование новой косметички: какие средства нужны каждой королеве.
        </li>
        <li className={liExclamationStyle}>Трендовый макияж от звездного визажиста за 10 минут в день.</li> */}
        {/* <p>Какие средства для него нужны и как делать себе по истине королевский макияж 👑</p> */}

        {/* <li className={liArrowStyle}>
          НОВЫЙ ГОД С АЛИНОЙ ШПАК - Все, кто купит участие в агрессивной тренировке,
          получат доступ к закрытой энергетической практике 31 ДЕКАБРЯ.
        </li>
        <li className={liAgreeStyle}>
          Акцент на попку, ножки, ручки (живот подтягивается автоматом благодаря
          технике дыхания)
        </li>
        <li className={liAgreeStyle}>Упражнения на осанку</li>
        <li className={liFireWorkStyle}>
          На этой тренировке будут ЛЕГЕНДАРНЫЕ энергетические УПРАЖНЕНИЯ НА БАБЛО💰
        </li>
        <li className={liAgreeStyle}>РОЗЫГРЫШ ПОЛМИЛЛИОНА
          РУБЛЕЙ</li>
        <li className={liExclamationStyle}>Лекции от топового ЭКСПЕРТА ПО ЭТИКЕТУ</li>
        <li className={liExclamationStyle}>УСИЛЕНИЕ женской и денежной энергии</li> */}
      </ul>

      <p>
        Без диет, не выходя из дома мы меняем образ мышления и развиваем
        энергии: денежную, сексуальную, энергию любви и жизни! Вы ощутите
        невероятный прилив энергии, так как правильное дыхание работает на
        клеточном уровне. Вы изучите правила Вселенной, при использовании
        которых вы сможете повысить эффективность и качество жизни.
      </p>

      <p>
        #ШпакМетод Pro естественным образом поможет скорректировать
        распределение жировой ткани, снизить чувство голода и нормализовать
        аппетит, что обеспечит потерю объемов без диет.
      </p>

      <p>
        Помимо того, что в ходе тренировки ваше тело приобретёт новые очертания и
        станет здоровым, ваша жизнь в целом перейдёт на новый уровень!
      </p>
    </div>
  );
  return (
    <>
      <TextSection content={content} />
    </>
  );
};

const Results = () => {
  const resultIconStyle =
    "text-white text-sm flex flex-col items-center gap-4 h-52 w-52 mb-10";
  const textSpanStyle = "flex text-center text-xs p-2";

  return (
    <div className="prose mx-auto min-w-full gap-x-4 bg-rose-500 p-4 text-center">
      <h1 className="mt-20 font-serif text-white">
        Результаты от техники #ШпакМетод Pro::
      </h1>
      <div className="flex w-full flex-col flex-wrap content-center items-center justify-between md:flex-row">
        <div className={resultIconStyle}>
          <div className="flex h-28 w-28  content-center items-center justify-center">
            <BodySVG />
          </div>
          <div>
            <span className={textSpanStyle}>
              Уменьшится объём тела (размеры одежды)
            </span>
          </div>
        </div>
        <div className={resultIconStyle}>
          <div className="flex h-28  w-28 items-center justify-center">
            <BackSideSVG />
          </div>
          <div>
            <span className={textSpanStyle}>Укрепление мышцы тазового дна</span>
          </div>
        </div>
        <div className={resultIconStyle}>
          <div className="flex h-28  w-28 items-center justify-center">
            <ForkAndSpoonSVG />
          </div>
          <div>
            <span className={textSpanStyle}>
              Сократится объём желудка, cнизится аппетит
            </span>
          </div>
        </div>
        <div className={resultIconStyle}>
          <div className="flex h-28  w-28 items-center justify-center">
            <EdemaSVG />
          </div>
          <div>
            <span className={textSpanStyle}>Уйдут отеки и лишняя вода</span>
          </div>
        </div>
        <div className={resultIconStyle}>
          <div className="flex h-28  w-28 items-center justify-center">
            <PostureSVG />
          </div>
          <div>
            <span className={textSpanStyle}>Выравнивание осанки</span>
          </div>
        </div>
        <div className={resultIconStyle}>
          <div className="flex h-28  w-28 items-center justify-center">
            <ImmuneSVG height="100%" />
          </div>
          <div>
            <span className={textSpanStyle}>Нормализуется нервная система</span>
          </div>
        </div>
        <div className={resultIconStyle}>
          <div className="flex h-28  w-28 items-center justify-center">
            <FlashSVG height="100%" />
          </div>
          <div>
            <span className={textSpanStyle}>Повысится уровень энергии</span>
          </div>
        </div>
        <div className={resultIconStyle}>
          <div className="flex h-28  w-28 items-center justify-center">
            <ChatSVG height="100%" />
          </div>
          <div>
            <span className={textSpanStyle}>
              Научитесь любить себя и других людей
            </span>
          </div>
        </div>
        <div className={resultIconStyle}>
          <div className="flex h-28  w-28 items-center justify-center">
            <YogaSVG height="100%" />
          </div>
          <div>
            <span className={textSpanStyle}>
              Повысится уровень концентрации и дисциплины
            </span>
          </div>
        </div>
        <div className={resultIconStyle}>
          <div className="flex h-28 w-28 items-center justify-center md:h-28 md:w-28">
            <StandSVG height="100%" />
          </div>
          <div>
            <span className={textSpanStyle}>
              Появится уверенность в себе и вкус жизни
            </span>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

const HowItGoes = () => {
  const howPass = (
    <div>
      <h2>
        7 дней каждое утро в 8:00 МСК выходит урок, который состоит из 3 частей:
      </h2>
      <ul>
        <li className={liAgreeStyle}>Детальный разбор новых упражнений</li>
        <li className={liAgreeStyle}>Тренировка</li>
      </ul>{" "}
      <ul>
        <li className={liAgreeStyle}>
          Лекция по проработке энергии человека во всех сферах жизни.
        </li>
      </ul>{" "}
      <ul>
        <li className={liAgreeStyle}>
          Доступ к информации сохраняется на 2 недели со дня начала тренировки.
        </li>
        <li className={liAgreeStyle}>
          В ПЕРВЫЙ ДЕНЬ тренировки мы подробно разбираем технику дыхания.
        </li>
      </ul>
      <h3>Что нужно подготовить для тренировки:</h3>
      <ul>
        <li className={liAgreeStyle}>
          Коврик или большое полотенце для занятий
        </li>
        <li className={liAgreeStyle}>
          Антицеллюлитный крем, крем для груди, маски для лица, патчи по желанию
        </li>
        <li className={liAgreeStyle}>
          Лук для тренировки - от «голышом» до спорт костюма, обувь не нужна.
          (стирать каждый день!)
        </li>
        <li className={liAgreeStyle}>
          Найти комфортный уголок дома или вне. Рекомендуется выполнять
          упражнения перед зеркалом либо записывать себя на камеру, чтобы
          самостоятельно контролировать технику выполнения упражнений. Ваша жопа
          в ваших руках.
        </li>
      </ul>
    </div>
  );
  return <TextSection content={howPass} />;
};

const liExlamationStyle =
  "before:content-['❗'] before:-left-6 before:text-xl before:absolute relative";

const Rules = () => {
  const rulesContent = (
    <div>
      <h2>Правила участия:</h2>
      <p>
        <strong>
          ШАГ 1. Оплата происходит через Robokassa на сайте
          ilovebodyflex.online.
        </strong>
      </p>
      <p>
        Внимательно указывайте свои личные данные в анкете на сайте (c
        неправильными данными обратная связь невозможна);
      </p>
      <p>
        <strong>
          ШАГ 2. После оплаты - вам нужно отправить запрос в закрытый Телеграм
          канал - именно там будет проходить тренировка.{" "}
          <a href="https://t.me/+bjm578CRjxlhNmVi" className="text-rose-600">
            Перейти.
          </a>{" "}
        </strong>
      </p>
      <p>❗ Добавление в закрытый Телеграм канал, в течении 2-х дней.</p>
      <p>
        Если у вас возникли проблемы с добавлением или оплатой, вы можете
        написать в Телеграм{" "}
        <a href="https://t.me/shpakmetod">
          <a href="https://t.me/shpakmetod">@shpakmetod</a>
        </a>
      </p>
      <h2 className="block text-rose-500">Важно:</h2>
      <ul className="list-none">
        <li className={liExlamationStyle}>
          После начала тренировки оплата не возвращается и не переносится ни по
          каким причинам;
        </li>
        <li className={liExlamationStyle}>
          Моя тренировка - мои правила! Что говорить, как рассуждать, темы лекции и
          все остальное на тренировке я определяю сама. Ваши советы и рекомендации
          просьба оставить при себе;
        </li>
        <li className={liExlamationStyle}>Скачайте приложение Telegram;</li>
        <li className={liExlamationStyle}>
          Заранее будьте уверены в вашем доступе к интернету, его скорости;
        </li>
        <li className={liExlamationStyle}>
          Ещё раз внимательно ознакомьтесь с противопоказаниями;
        </li>
        <li className={liExlamationStyle}>
          Копирование материла с тренировки, его распространение запрещено и
          преследуется законом;
        </li>
        <li className={liExlamationStyle}>Детальный разбор новых упражнений</li>
        <li className={liExlamationStyle}>
          Придерживаемся принципа персональной ответственности за свое здоровье.
        </li>
      </ul>
    </div>
  );
  return <TextSection content={rulesContent} />;
};

const TestimonialsIntro = () => {
  const testimonialsText = (
    <div>
      <h2>Отзывы моих королев</h2>
      <p>Школа Алины Шпак настроена на результат</p>
      <p>
        <span className="font-bold text-rose-500">#королевыбодифлекса</span>{" "}
        начинают выкидывать и раздавать одежду, которая им велика. Когда, они
        едят то, что им нравится и живут в кайф. А потом пишут мне, что худеют
        несмотря ни на что. Когда возвращаются с отпусков меньше на размер.
        Когда не парятся, что б такого съесть, чтоб похудеть
      </p>
      <p>
        Потому что <span className="font-bold text-rose-500">#шпакметод</span>{" "}
        работает на них. На их мировосприятие, тело и РЕЗУЛЬТАТ. Зачем
        сюсюканья, если это не приблизит вас к телу без жира?
      </p>
    </div>
  );
  return <TextSection content={testimonialsText} />;
};

export type TestimonialType = {
  text: string | JSX.Element;
  name: string;
  avatar?: string;
  likes: number;
  show?: boolean;
};

const testimonials: TestimonialType[] = [
  {
    name: "daryadoshenko",
    avatar: "review-avatar-1.png",
    text: (
      <p>
        👍легко и очень просто, главное делать правильно) ❤️мне очень нравится)
        буду ждать теперь эфир с техникой пожёстче)😁 я после зарядки как
        пропеллер ношусь ...) 😘 благодарю
      </p>
    ),
    likes: 27,
  },
  {
    name: "smartcosmetolog",
    avatar: "ava-8.jpeg",
    text: (
      <p>
        Я начала с Алиной работать в августе: я маленькая и поэтому каждый кг
        виден 🙈 к сегодняшнему дню -3,5 кг 💪 в объёмах только под грудью -8 см
        🔥🔥🔥 Теперь у меня задача ещё -4 кг с оксисайз 👌 Мне нравится подход
        Алины: всё просто, делайте, как говорю, главное каждый день))) Реально
        всё работает — первый результат виден через несколько дней 💪 Это
        ГЕНИАЛЬНАЯ СИСТЕМА 🥇"
      </p>
    ),
    likes: 159,
  },
  {
    name: "katri_na93",
    avatar: "ava-2.jpeg",
    text: (
      <p>
        Не так-то просто уложить всю благодарность в одно сообщение 😍 началось
        с того, что после родов, я сильно поправилась, хоть кг и показывали
        примерно такие же цифры как до беременности, но объемы были просто
        огромными! Пол года я наблюдала за Вашей страничкой, сначала одним
        глазом, потом ждала, когда начнётся тренировка! Я заранее настраивала себя
        на плохой результат, ведь маленький ручной ребёнок весь день со мной, да
        и сил не было совсем, пока малышка спала, я тоже спала, и ещё безумно
        спина болела, дочку не могла поднимать, если только со слезами. После
        тренировки, кстати, собиралась к остеопату насчёт спины. Вот началась
        тренировка, нашла в себе силы не спать, а смотреть эфир, делала все с
        опозданием на один день. А дальше началось нечто... на третий день
        тренировки я проснулась без боли в спине... пишу и прям плачу... я на
        протяжении нескольких месяцев не могла даже лежать без боли, а тут
        такое...
      </p>
    ),
    likes: 137,
  },
  {
    name: "alinkashpak",
    avatar: "ava-1.jpeg",
    text: (
      <p>
        100% работает, а фраза «Ваша жопа в ваших рука» спасёт мир!!! Алина, не
        перестаю вас благодарить, жир тает, а энергия только прибавляется,
        участвовала уже в 2-х тренировках и жду 3-ю!!! ❤️❤️❤️😘
      </p>
    ),
    likes: 152,
  },
  {
    name: "la_skava",
    avatar: "ava-3.jpeg",
    text: (
      <p>
        Алииииииинааа! Это невероятно!!! Я в шоке, поэтому ступор в словах моей
        благодарности!!! Но! Три недели моей первой тренировки с тобой и
        тааакиие результаты!!! Грудь без изменений! Талия минус 4 см, живот
        минус 3, бёдра минус 2!!!! А ноги! Ноги!!! Я уже забыла какой они формы,
        мои прекрасные длинные ноги!! Постоянно отечные и жирноватые((( При
        росте 175 и весе 68. Сейчас объёмы 93-74-100!!!! Я навеки с Вами! Всё
        работает!!
      </p>
    ),
    likes: 152,
  },
  {
    name: "talkree78",
    avatar: "ava-4.jpeg",
    text: (
      <p>
        Привет дышу с тобой уже Неделю и не знаю или это от головы или реально
        всё так классно, живот мой уходит, хотя я пью пиво в выхи и не могу
        забыть про него, но уже не утро без дыхания, как наркотик. Встаю и бегом
        дышать с мелкой, она за мной всё повторяет 😊 Надеюсь скоро стать худой
        и подтянутой 😊 😛
      </p>
    ),
    likes: 189,
  },
  {
    name: "tan_tan",
    avatar: "ava-5.jpeg",
    text: (
      <p>
        Алина, благодарю за тренировку ❤️ Более понятного объяснения техники и
        упражнений я не слышала нигде ещё ✊ У меня тариф без обратной связи, но
        я считаю, что всё более, чем понятно 👌👌👌 Подписана давно на тебя,
        пробовала делать и #шпакметод_зарядка и упражнения в свободном доступе
        по бодифлексу, думала всё уже знаю ✌️ такая деловая, хрен там 🤚🙈🙊 В
        тренировке я совершенно по-другому начала дышать, более осознанно все 5
        этапов, прям супер 💫 Помогают твои объяснения деталей мелких, вроде как
        итак понятно, но вот именно там подкрутил, тут голову наклонил и всё,
        совсем другое дело, совсем другой результат, и живот лучше уходит под
        рёбра и на пятом этапе хочется вдохнуть как положено, а то ж когда
        самостоятельно делала, думала, ну что тяжёлого-то, задержать на 10 сек.,
        фи, я могу и дольше, ага, сейчас до 8-9 секунд дотянуть бы 😐 В общем, я
        в восторге полнейшем 👏 Хочу теперь подышать с тобой оффлайн 💫💫💫
      </p>
    ),
    likes: 189,
  },
  {
    name: "nenris77",
    avatar: "ava-6.jpeg",
    text: (
      <p>
        И вот сейчас: качество тела изменилось, целлюлит ушёл, влезла во все
        вещи. Работы ещё немного и я навеки Ваша... 💙💙💙 пью вино и ем всё,
        что хочу, дышу по утрам и слушаю чашу... доверяю и верю и дальше
        наблюдаю... как изменится моё тело, как изменюсь я и всё вокруг...
        дыхательные техники — это не только про жопу, это про всю жизнь... я
        выдохнула благодаря Вам Спасибо 🙏
      </p>
    ),
    likes: 189,
  },
  {
    name: "tatkach66",
    avatar: "ava-7.jpeg",
    text: (
      <p>
        Тренировка!! Просто супер! Мне очень нравится! Бодифлекс тоже интересен! Но
        оксисайз просто 🔥 !!! Результаты лично у меня более ощутимее!! Всё тело
        ломит от мышечной боли. Очень люблю такое состояние. Спасибо огромное!!
        Ждём продолжения!
      </p>
    ),
    likes: 189,
  },
];

const products = [
  {
    id: 32,
    name: "Стандарт",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытому Телеграм каналу 14 дней, в котором проходит
          тренировка.{" "}
          <a href="https://t.me/+bjm578CRjxlhNmVi" className="text-rose-600">
            Перейти.
          </a>
        </li>
        <li className="font-bold">
          Без обратной связи и проверки домашнего задания
        </li>
        <li className="font-bold text-rose-600">
          Обращаем ваше внимание, что вам необходимо
          САМОСТОЯТЕЛЬНО отправить запрос в группу
          тренировки.
        </li>
        <li className="font-bold text-rose-600">
          Внимательно указывайте ваши данные, при
          заполнении анкеты.
        </li>
      </ul>
    ),
    price: "8 000 ₽",
  },
  {
    id: 33,
    name: "Стандарт плюс",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытому Телеграм каналу 14 дней, в котором проходит
          тренировка.{" "}
          <a href="https://t.me/+bjm578CRjxlhNmVi" className="text-rose-600">
            Перейти.
          </a>
        </li>
        <li className="font-bold">
          ПРОВЕРКА ТЕХНИКИ ДЫХАНИЯ В ГРУППЕ в wats’app в течение 32 часов после
          начала тренировки Далее без обратной связи и проверки домашнего задания{" "}
        </li>
        <li className="font-bold">Жесткий режим и тотальный контроль</li>
        <li className="font-bold text-rose-600">
          Обращаем ваше внимание, что вам необходимо
          САМОСТОЯТЕЛЬНО отправить запрос в группу
          тренировки.
        </li>
        <li className="font-bold text-rose-600">
          Внимательно указывайте ваши данные, при
          заполнении анкеты.
        </li>
      </ul>
    ),
    price: "10 000 ₽",
  },
  {
    id: 34,
    name: "VIP",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытому Телеграм каналу 14 дней, в котором проходит
          тренировка.{" "}
          <a href="https://t.me/+bjm578CRjxlhNmVi" className="text-rose-600">
            Перейти.
          </a>
        </li>
        <li className="font-bold">
          Проверка всех домашних заданий в общем vip чате в WhatsApp (с 15 по 21 мая)
        </li>
        <li className="font-bold">
          Подарок сумка-шоппер ( доставка только по РФ, осуществляется только один раз )
        </li>
        <li className="font-bold">Жесткий режим и тотальный контроль</li>
        <li className="font-bold text-rose-600">
          Обращаем ваше внимание, что вам необходимо
          САМОСТОЯТЕЛЬНО отправить запрос в группу
          тренировки.
        </li>
        <li className="font-bold text-rose-600">
          Внимательно указывайте ваши данные, при
          заполнении анкеты.
        </li>
      </ul>
    ),
    price: "15 000 ₽",
  },
  {
    id: 35,
    name: "VIP+",
    description: (
      <ul>
        <li className="font-bold">
          Доступ к закрытому Телеграм каналу 14 дней, в котором проходит
          тренировка.{" "}
          <a href="https://t.me/+bjm578CRjxlhNmVi" className="text-rose-600">
            Перейти.
          </a>
        </li>
        <li className="font-bold">
          ПРОВЕРКА домашних заданий лично АЛИНОЙ ШПАК в Закрытом ЧАТЕ В wats’app
        </li>
        <li className="font-bold">Жесткий режим и тотальный контроль</li>
        <li className="font-bold text-rose-600">
          Обращаем ваше внимание, что вам необходимо
          САМОСТОЯТЕЛЬНО отправить запрос в группу
          тренировки.
        </li>
        <li className="font-bold text-rose-600">
          Внимательно указывайте ваши данные, при
          заполнении анкеты.
        </li>
      </ul>
    ),
    price: "100 000 ₽",
  },
];

export const Pro = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const qaRef = useRef<HTMLDivElement>(null);
  const howItGoesRef = useRef<HTMLDivElement>(null);
  const rulesRef = useRef<HTMLDivElement>(null);
  const testimonialsRef = useRef<HTMLDivElement>(null);
  const contraindicationRef = useRef<HTMLDivElement>(null);
  const buyRef = useRef<HTMLDivElement>(null);

  const scrollTo = (name: string) => {
    if (name === "about") window.scrollTo(0, aboutRef?.current?.offsetTop || 0);
    if (name === "results")
      window.scrollTo(0, resultsRef?.current?.offsetTop || 0);
    if (name === "qa") window?.scrollTo(0, qaRef?.current?.offsetTop || 0);
    if (name === "howItGoes")
      window?.scrollTo(0, howItGoesRef?.current?.offsetTop || 0);
    if (name === "rules")
      window?.scrollTo(0, rulesRef?.current?.offsetTop || 0);
    if (name === "testimonials")
      window?.scrollTo(0, testimonialsRef?.current?.offsetTop || 0);
    if (name === "contraindication")
      window?.scrollTo(0, contraindicationRef?.current?.offsetTop || 0);
    if (name === "buy") window?.scrollTo(0, buyRef?.current?.offsetTop || 0);
  };

  return (
    <div className="relative">
      <HeaderPro scrollTo={scrollTo} />
      <HeroSection scrollTo={scrollTo} />
      <div ref={aboutRef} />
      <AboutSection />
      <div ref={resultsRef} />
      <Results />
      <div ref={howItGoesRef} />
      <HeroImageContainer imageName="how-pass.png" />
      <HowItGoes />
      <div ref={rulesRef} />
      <HeroImageContainer imageName="rules.png" />
      <Rules />
      <div ref={testimonialsRef} />
      <HeroImageContainer imageName="testimonials.png" />
      <TestimonialsIntro />
      <Testimonials testimonials={testimonials} />
      <TextSection
        content={
          <p>
            Проще говоря, цитирую своих Королев: «Алина, твои тренировки - лучшее
            вложение в себя в своё будущее». И это 100% истина, так как мы
            прокачиваем не только своё тело, но и мозги!
          </p>
        }
      />
      <div ref={contraindicationRef} />
      <Contraindications />
      <div ref={buyRef} />
      <Products date={"15 мая 2024"} products={products} closed={false} closedTitle="СКОРО ПРОДАЖИ" />
      <Footer background="gray" />
    </div>
  );
};


