interface IHeroImageProps {
  imageName: string;
  children?: React.ReactNode;
}

export const HeroImageContainer = ({ imageName, children }: IHeroImageProps) => {
	const image = require('assets/content/' + imageName)

  return (
    <div
			className="bg-cover bg-center bg-no-repeat h-96"
      style={{
        backgroundImage: `url(${image})`,
      }}
    > 
      {children}
    </div>
  );
};
