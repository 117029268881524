import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { ReactComponent as Burger } from "assets/svg/burger.svg";
import { forwardRef, useState } from "react";

type HeaderProps = {
  scrollTo: (name: string) => void;
};

export const HeaderPro = forwardRef<HTMLDivElement, HeaderProps>(
  ({ scrollTo }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleScrollTo = (name: string) => {
      setIsOpen(false);
      scrollTo(name);
    };

    const MenuItems = ({ className }: { className?: string }) => (
      <>
        <div
          onClick={() => {
            handleScrollTo("about");
          }}
          className={`${className} cursor-pointer text-rose-500`}
        >
          #ШпакМетод Pro
        </div>
        <div
          onClick={() => {
            handleScrollTo("results");
          }}
          className={`${className} cursor-pointer text-rose-500`}
        >
          Результаты
        </div>
        <div
          onClick={() => {
            handleScrollTo("howItGoes");
          }}
          className={`${className} cursor-pointer text-rose-500`}
        >
          Как проходит
        </div>
        <div
          onClick={() => {
            handleScrollTo("rules");
          }}
          className={`${className} cursor-pointer text-rose-500`}
        >
          Правила участия
        </div>
        <div
          onClick={() => {
            handleScrollTo("testimonials");
          }}
          className={`${className} cursor-pointer text-rose-500`}
        >
          Отзывы
        </div>
      </>
    );

    return (
      <div className="absolute w-full z-900 top-0">
        <nav className="bg-header flex pl-9 w-full justify-between items-center sticky top-0 z-10">
          <div ref={ref} className="h-16 flex items-center flex-none">
            <a href="/">
              <Logo className="cursor-pointer" />
            </a>
          </div>
          <div className="font-sans justify-end items-center grow gap-10 w-auto hidden lg:flex mr-8">
            <MenuItems />
          </div>
          <div className="lg:hidden flex items-end justify-end grow mr-6 cursor-pointer">
            <Burger onClick={() => setIsOpen((prev) => !prev)} />
          </div>
          {isOpen && (
            <div
              className={`${
                !isOpen ? "opacity-0 hidden" : "opacity-100 visible"
              } transition ease-in-out duration-700 `}
            >
              <div className="absolute top-16 bg-white flex flex-col p-8 divide-y -left-0 w-full divide-dashed">
                <MenuItems className="p-2 text-rose-500" />
              </div>
            </div>
          )}
        </nav>
      </div>
    );
  }
);
