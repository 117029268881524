import { AiOutlineCloseCircle } from "react-icons/ai";

export const Modal = ({
  children,
  isModalOpen,
  setIsModalOpen,
  fixed = true,
}: any) => {
  const onDismiss = () => {
    setIsModalOpen(false);
  };

  return (
    isModalOpen && (
      <div
        className={`flex bg-rose-500 justify-center items-center overflow-x-hidden ${
          fixed ? "fixed" : ""
        } visible overflow-y-auto inset-0 z-50 outline-none focus:outline-none`}
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl min-w-min p-2">
          <div className="p-4 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div>
              <AiOutlineCloseCircle
                className="absolute text-gray-500 w-8 h-8 right-4 top-4 cursor-pointer"
                onClick={() => onDismiss()}
              />
            </div>
            {children}
          </div>
        </div>
      </div>
    )
  );
};
